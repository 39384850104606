export class AdminLanguage {
    STT: string;
    danh_sach_dia_diem: string;
    dia_diem: string;
    them_dia_diem: string;
    cap_nhat_dia_diem: string;
    to_chuc_: string;
    ma: string;
    ten_viet_tat: string;
    ma_dia_diem: string;
    trang_thai: string;
    xu_ly: string;
    dong: string;
    ban_co_chac_chan_muon_xoa: string;
    xac_nhan_xoa: string;
    them_dia_diem_mac_dinh: string;
    to_chuc: string;
    ten_dia_diem: string;
    ban_chua_nhap_ten_dia_diem: string;
    dia_diem_co_it_nhat_3_ki_tu: string;
    dia_diem_co_nhieu_nhat_50_ki_tu: string;
    ban_chua_nhap_ma_dia_diem: string;
    ma_dia_diem_co_it_nhat_3_ki_tu: string;
    ma_dia_diem_co_nhieu_nhat_50_ki_tu: string;
    tom_tat: string;
    ban_chua_nhap_tom_tat: string;
    tom_tat_co_it_nhat_3_ki_tu: string;
    tom_tat_co_nhieu_nhat_50_ki_tu: string;
    danh_muc: string;
    thoi_gian_hoat_dong: string;
    gio_mo_cua: string;
    hoat_dong: string;
    co: string;
    khong: string;
    xoa: string;
    them: string;
    cap_nhat: string;
    thuoc_dia_diem: string;
    ban_chua_nhap_gio_mo_cua: string;
    ban_chua_nhap_gio_dong_cua: string;
    yeu_cau_nhap_day_du_thong_tin: string;
    //  Company
    danh_sach_cong_ty: string;
    them_cong_ty: string;
    cong_ty: string;
    mo_ta: string;
    ngay_tham_gia: string;
    ban_chua_nhap_ten_cong_ty: string;
    logo_cong_ty: string;
    ma_cong_ty: string;
    ban_chua_nhap_ma_cong_ty: string;
    ban_chua_nhap_ten_viet_tat: string;
    ban_chua_nhap_mo_ta: string;
    ban_chua_nhap_subdomain: string;
    Subdomain: string;
    nguoi_dung_quan_tri: string;
    ban_chua_nhap_nguoi_quan_tri: string;
    Email_quan_tri: string;
    ban_chua_nhap_email: string;
    mat_khau: string;
    ban_chua_nhap_mat_khau: string;
    Module_su_dung: string;
    cap_nhat_cong_ty: string;
    // Location
    danh_sach_vi_tri: string;
    tim_kiem_: string;
    vi_tri: string;
    ma_vi_tri: string;
    them_vi_tri: string;
    khong_co_du_lieu: string;
    cap_nhat_vi_tri: string;
    chua_chon: string;
    ban_chua_nhap_vi_tri: string;
    vi_tri_co_it_nhat_3_ki_tu: string;
    vi_tri_co_nhieu_nhat_50_ki_tu: string;
    // Category
    danh_sach_danh_muc: string;
    them_moi: string;
    ngay_cap_nhat: string;
    them_danh_muc: string;
    cap_nhat_danh_muc: string;
    ma_danh_muc: string;
    ban_chua_nhap_danh_muc: string;
    danh_muc_co_it_nhat_3_ki_tu: string;
    // Device list
    danh_sach_thiet_bi: string;
    so_dong: string;
    chua_cap_nhat: string;
    them_thiet_bi: string;
    cap_nhat_thiet_bi: string;
    ban_chua_nhap_seri: string;
    serial_number_co_it_nhat_3_ki_tu: string;
    ban_chua_nhap_IP: string;
    ban_chua_chon_vi_tri: string;
    ten_thiet_bi: string;
    che_do: string;
    dia_chi_IP: string;
    dia_chi_wan_ip: string;
    dang_hoat_dong: string;
    ngat_ket_noi: string;
    ban_co_chac_chan_muon_khoi_phuc: string;
    xin_luu_y: string;
    ban_chua_nhap_ten_thiet_bi: string;
    kich_hoat: string;
    dia_chi_mac: string;
    cong_http: string;
    cong_https: string;
    chon_loc_mien: string;
    chua_xoa: string;
    da_xoa: string;
    chua_co_ten: string;
    ngay_tao: string;
    them_moi_thiet_bi: string;
    chon_mien: string;
    dang_tai_du_lieu: string;
    dang_xu_ly_du_lieu: string;
    tim_theo_serial_number: string;
    so_thu_tu: string;
    logo_ung_dung: string;
    them_moi_thanh_cong: string;
    cap_nhat_thanh_cong: string;
    xoa_thanh_cong: string;
    loi_xu_ly_server: string;
    ban_da_xoa_thanh_cong: string;
    co_loi_xay_ra: string;
    ban_chua_chon_anh_logo_ung_dung: string;
    ban_chua_chon_anh_logo_cong_ty: string;
    tiep_theo: string;
    khong_the_ket_noi_may_chu_vui_long: string;
    khong_the_ket_noi_may_chu: string;
    loi_du_lieu_khong_the_thay_doi: string;
    du_lieu_khong_phu_hop: string;
    chon_tat_ca: string;
    bo_chon_tat_ca: string;
    tinh_trang: string;
    chon_chi_so: string;
    ten_mien_sub: string;
    ban_chua_nhap_ten_mien: string;
    chi_so_su_dung: string;
    gui_mat_khau_moi_thanh_cong: string;
    thong_tin_khong_thay_doi: string;
    khong_the_ket_noi_du_lieu: string;
    site_khong_duoc_de_trong: string;
    to_chuc_chua_co_dia_diem: string;
    tuy_chon: string;
    tu_khoa: string;
    tim_theo_email: string;
    tim_kiem: string;
    danh_sach_nguoi_dung: string;
    them_tai_khoan: string;
    ho_va_ten: string;
    cac_email_ngan_cach_nhau: string;
    vui_long_nhap_ho_ten: string;
    vui_long_nhap_ho_ten_5_ki_tu: string;
    vui_long_nhap_ho_ten_100_ki_tu: string;
    vui_long_nhap_email: string;
    vui_long_nhap_email_hop_le: string;
    vui_long_nhap_email_ki_tu: string;
    cap_nhat_quyen_su_dung_cho: string;
    cap_nhat_quyen: string;
    ma_quyen: string;
    ma_nguoi_dung: string;
    ma_to_chuc: string;
    mo_ta_nhom_quyen: string;
    nhom_nguoi_dung: string;
    cua_hang: string;
    nguoi_cap_nhat: string;
    gui_mat_khau_chac_chan: string;
    gui_mat_khau_moi: string;
    email_khac: string;
    cap_nhat_tai_khoan: string;
    danh_sach_ung_dung: string;
    _ung_dung: string;
    ma_ung_dung: string;
    ten_ung_dung: string;
    ten_ung_dung_3_ki_tu: string;
    nhap_ten_ung_dung: string;
    ban_chua_nhap_url: string;
    url_3_ki_tu: string;
    anh_khong_du_tieu_chuan: string;
    anh_so: string;
    ban_dong_y_xoa: string;
    qua_trinh_xu_ly_anh_khong_thanh_cong: string;
    danh_sach_khach_hang: string;
    ban_hay: string;
    du_lieu_duoc_ghi_nhan: string;
    bam_vao_day: string;
    tai_lai: string;
    ten_khach_hang: string;
    ma_khach_hang: string;
    sdt: string;
    hinh_anh: string;
    them_khach_hang: string;
    nhap_ten_khach_hang: string;
    them_khach_hang_3_ki_tu: string;
    nhap_so_dien_thoai: string;
    sdt_10_so: string;
    ngay_sinh: string;
    ban_chua_nhap_ngay_sinh: string;
    anh_dai_dien: string;
    chinh_sua: string;
    chinh_sua_thong_tin_kh: string;
    _tuy_chon: string;
    anh_nhan_dang: string;
    chua_co_anh_nhan_dang: string;
    Luu_y_upload_anh: string;
    danh_sach_black_list: string;
    them_black_list: string;
    ten_black_list: string;
    ten_black_list_3_ki_tu: string;
    nhap_ten_black_list: string;
    ma_black_list: string;
    chinh_sua_black_list: string;
    chua_xac_dinh: string;
    danh_sach_nhan_vien: string;
    them_nhan_vien: string;
    ten_nhan_vien: string;
    ma_nhan_vien: string;
    ma_mien: string;
    ten_mien: string;
    chinh_sua_ten_nhan_vien: string;
    nhap_ten_nhan_vien: string;
    ten_nhan_vien_3_ki_tu: string;
    to_chuc_khong_co_zalo: string;
    danh_sach_tai_khoan_zalo: string;
    ten_hien_thi: string;
    ten_zalo: string;
    gioi_tinh: string;
    chua_dat_ten: string;
    nam: string;
    nu: string;
    ma_tai_khoan: string;
    ten_tai_khoan: string;
    nhap_ten_tai_khoan: string;
    ten_tai_khoan_3_ki_tu: string;
    ten_tai_khoan_60_ki_tu: string;
    nhap_ten_he_thong: string;
    ten_he_thong_3_ki_tu: string;
    ten_he_thong_60_ki_tu: string;
    nhap_ma_OA: string;
    ma_OA_3_ki_tu: string;
    ma_OA_60_ki_tu: string;
    nhap_invite_code: string;
    invite_code_3_ki_tu: string;
    invite_code_60_ki_tu: string;
    nhap_secret: string;
    secret_3_ki_tu: string;
    secret_60_ki_tu: string;
    danh_sach_official: string;
    nguoi_duoc_chon: string;
    chon_su_kien: string;
    cap_nhat_su_kien: string;
    tinh_trang_thiet_bi: string;
    gio: string;
    ngay: string;
    tuan: string;
    thang: string;
    phut: string;
    tat_ca: string;
    su_kien: string;
    su_kien_nhan_tin_nhan: string;
    danh_sach_webhook: string;
    gui_du_lieu_mau: string;
    dia_chi_nhan_duoc: string;
    phuong_thuc_gui_di: string;
    du_lieu_gui_di: string;
    ban_chua_nhap: string;
    phai_co_it_nhat: string;
    cau_hinh_gui_email: string;
    nhap_lai_mat_khau: string;
    loai_thiet_bi: string;
    cap_nhat_chi_so: string;
    khong_ton_tai_module: string;
    danh_sach_cau_hinh_nhan_email_bao_cao_dinh_ky: string;
    ten_module: string;
    bao_cao: string;
    loai_bao_cao: string;
    xem_theo: string;
    thoi_gian_bat_dau: string;
    thoi_gian_ket_thuc: string;
    ngung_hoat_dong: string;
    vui_long: string;
    de_tai_lai: string;
    dang_ky_nhan_bao_cao: string;
    chon_module: string;
    mau_bao_cao: string;
    khung_gio: string;
    cap_nhat_cau_hinh_nhan_mail: string;
    tuy_chon_nhap: string;
    da_co_loi_xay_ra: string;
    mat_khau_cu: string;
    mat_khau_moi: string;
    doi_mat_khau: string;
    bao_cao_dinh_ky: string;
    khong_xac_dinh: string;
    them_cau_hinh_nhan_mail_tu_dong: string;
    chon_to_chuc: string;
    xem_theo_tung_trang: string;
    xem_theo_tung_nguoi_dung: string;
    email_nhan_bao_cao_dinh_ky: string;
    cau_hinh: string;
    quan_tri_cau_hoi: string;
    dien_thoai_may_tinh_bang: string;
    nguoi_dung: string;
    nhap_du_lieu: string;
    quan_tri_thiet_bi: string;
    chon_trang: string;
    tong_so_ban_ghi: string;
    ten_trang: string;
    ten_nguoi_dung: string;
    vui_long_chon_dia_diem: string;
    cap_nhat_cau_hinh_nhan_mail_tu_dong: string;
    khong_trung_khop: string;
    vui_long_nhap_lai: string;
    xac_thuc_email: string;
    xac_thuc: string;
    chua_xac_nhan: string;
    khoi_phuc: string;
    cap_nhat_du_lieu_thanh_cong: string;
    ten_danh_muc: string;
    cap_nhat_thong_tin: string;
    co_it_nhat: string;
    ki_tu: string;
    vui_long_nhap: string;
    quay_lai: string;
    tieu_de_send: string;
    ban_chua_nhap_tieu_de_nut_send: string;
    tieu_de_cancel: string;
    ban_chua_nhap_tieu_de_nut_cancel: string;
    tieu_de_khung_ly_do: string;
    ban_chua_nhap_khung_ly_do: string;
    tieu_de_goi_y: string;
    ban_chua_nhap_tieu_de_goi_y: string;
    tieu_de_ly_do: string;
    ban_chua_nhap_tieu_de_ly_do: string;
    tieu_de_thong_tin_khach_hang: string;
    ban_chua_nhap_tieu_de_thong_tin_khach_hang: string;
    tieu_de_khung_ten_kh: string;
    ban_chua_nhap_tieu_de_khung_ten_kh: string;
    tieu_de_khung_email_kh: string;
    ban_chua_nhap_tieu_de_khung_email_kh: string;
    tieu_de_khung_sdt_kh: string;
    ban_chua_nhap_tieu_de_khung_sdt_kh: string;
    yeu_cau_tu_chon: string;
    yeu_cau_ten_khach_hang: string;
    yeu_cau_email_khach_hang: string;
    yeu_cau_sdt_khach_hang: string;
    thong_diep_ket_thuc: string;
    ban_chua_nhap_thong_diep_ket_thuc: string;
    ban_chua_nhap_id: string;
    ban_chua_nhap_private_key: string;
    ban_chua_nhap_ten_hien_thi: string;
    ban_chua_nhap_ma_dang_ky: string;
    luu_cau_hinh: string;
    ma_dang_ky: string;
    dang_ky_su_kien: string;
    ban_chua_cau_hinh_oa_zalo: string;
    danh_sach_su_kien: string;
    nguoi_chua_chon: string;
    huy_dang_ky_thanh_cong: string;
    Anh_chi_co_muon_nhan_bao_cao_nay_qua_email: string;
    Bao_cao_nay_se_duoc_gui_tu_dong: string;
    vao: string;
    cua_ky_bao_cao: string;
    oh_ngay_tiep_theo: string;
    oh_thu_hai_dau_tuan: string;
    oh_ngay_dau_tien_trong_thang: string;
    oh_ngay_dau_tien_trong_nam: string;
    Dang_ky_thanh_cong: string;
    To_chuc: string;
    Tao_lich_gui_bao_cao: string;
    Cap_nhat_lich_gui_bao_cao: string;
    Danh_sach_dang_ky_nhan_mail_dinh_ky: string;
    Thoi_gian_dang_ky_nhan_email: string;
    Ban_chua_dang_ky_nhan_bao_cao: string;
    Hien_nut_bo_qua: string;
    dinh_dang_gio_hien_thi: string;
    h_12: string;
    h_24: string;
    ngan_hop_hop_thoai_xuat_hien: string;
    luu_phien_lam_viec_hien_tai: string;
}
