export const language = {
    nhap_du_lieu: 'Nhập dữ liệu',
    tai_file_mau: 'Tải file mẫu',
    nhap_du_lieu_thanh_menu: 'Nhập dữ liệu',
    chon_cua_hang: 'Chọn cửa hàng',
    time: 'Thời gian',
    chon_cho_so: 'Chọn chỉ số',
    chon_to_chuc: 'Chọn tổ chức',
    ghi_de: 'Ghi đè:',
    dang_xu_ly: ' Đang xử lý',
    da_cap_nhat: 'Đã cập nhật thành công',
    ban_ghi: 'bản ghi ',
    ban_ghi_loi: 'bản ghi cập nhật bị lỗi',
    content_tochuc: 'Vui lòng chọn tổ chức phù hợp!',
    content_cuahang: 'Vui lòng chọn cửa hàng phù hợp! ',
    content_thoigian: 'Vui lòng chọn thời gian bắt đầu, thời gian kết thúc cần nhập dữ liệu!',
    content_chiso: 'Vui lòng chọn các chỉ số phù hợp!',
    content_file: 'Vui lòng chọn file phù hợp! Ưu tiên file mẫu để tối ưu tốc độ nhập dữ liệu!',
    content_ghide: 'Vui lòng chọn chính xác chỉ số cần ghi đè! Chỉ số mới sẽ đè lên chỉ số cũ với cùng block thời gian!',
    popup_nhap_du_lieu: 'Nhập dữ liệu! Bạn có chắc chắn muốn nhập?',
    bo_qua: ' Bỏ qua',
    chap_nhan: 'Chấp nhận',
    vao: ' vào',
    va: 'và',
    chon_dia_diem: 'Chọn địa điểm',
    chon_thoi_ky: 'Chọn thời kỳ',
    tim_kiem_dia_diem: 'Tìm kiếm địa điểm/ khu vực',
    bao_cao_duoc_tao: 'Báo cáo này đã được tạo vào',
    lam_moi_bao_cao: 'làm mới báo cáo',
    gia_tri_trung_binh_cua_hang: 'Giá trị trung bình các cửa hàng',
    thoi_gian_trung_binh: 'Thời gian trung bình',
    thoi_gian_chenh_lech: 'Thời gian chênh lệch',
    thoi_gian_danh_gia: 'Thời gian đánh giá',
    ap_dung: ' Áp dụng',
    dong: 'Đóng',
    xuat: 'Xuất',
    thoi_gian: 'Thời gian',
    xem_theo: 'xem theo',
    Xem_theo: 'Xem theo',
    xem: 'Xem ',
    so_voi: 'so với',
    So_voi: 'So với',
    lan: 'lần)',
    tuong_duong: 'tương đương',
    luc: 'lúc',
    gio: 'Giờ',
    ngay: 'Ngày',
    tuan: 'Tuần',
    thang: 'Tháng',
    nam: 'Năm',
    phut: 'Phút',
    mot_phan_tu_gio: '15 phút',
    nua_gio: '30 phút',
    ngay_lam_viec: 'Ngày trong tuần',
    ngay_lam_viec_cuoi_tuan: 'Ngày làm việc, ngày cuối tuần',
    dong_cua_so: 'Đóng cửa sổ',
    gio_mo_cua: 'Giờ mở cửa:',
    gioi_tinh_gender: 'Giới tính - Gender',
    do_tuoi_age: 'Độ tuổi - Age range ',
    dia_diem_khu_vuc: 'Địa điểm khu vực',
    thoi_ky: 'Thời kỳ',
    trong_tong_so: 'trong tổng số',
    age: 'Độ tuổi',
    nu_female: 'Nữ - Female',
    nam_male: 'Nam - Male',
    khong_xac_dinh: 'Không xác định',
    cau_hinh: 'Cấu hình',
    hieu_qua_hoat_dong: 'Hiệu quả hoạt động',
    cua_hang: 'Cửa hàng',
    xuat_bao_cao: 'Xuất báo cáo',
    xuat_ra_excel: 'Xuất ra excel',
    ngay_bay_gio: 'Ngay bây giờ',
    so_voi_ngay_hom_qua: 'So với ngày hôm qua',
    khach_hang_dang_mua_sam: 'Khách hàng đang mua sắm',
    so_luong_khach_hien_tai: 'Số lượng khách hàng tới mua sắm đến thời điểm hiện tại',
    tong_khach_vao: 'Tổng lượt khách vào',
    tong_khach_ra: 'Tổng lượt khách ra',
    cau_hoi_khao_sat: 'Câu hỏi khảo sát',
    dang_dien_ra: 'Đang diễn ra',
    sap_dien_ra: 'Sắp diễn ra',
    ket_thuc: 'Kết thúc',
    so_dong: 'Số dòng:',
    tim_kiem: 'Tìm kiếm:',
    STT: 'STT',
    ho_va_ten: 'Họ và tên',
    dien_thoai: 'Điện thoại',
    danh_gia: 'Đánh giá',
    ly_do_khong_hai_long: 'Lý do không hài lòng',
    y_kien_bo_sung: 'Ý kiến bổ sung',
    khong_co_du_lieu: 'Không có dữ liệu',
    hien_thi_theo: 'Hiển thị theo:',
    phan_tram: 'Phần trăm',
    tong_so_danh_gia: 'tổng số đánh giá',
    danh_gia_bang: 'đánh giá',
    muc_do_khach_hang_dat_duoc: 'mức độ hài lòng của khách hàng bạn đạt được',
    khach_hang_san_sang_gioi_thieu: 'khách hàng sẵn sàng giới thiệu bạn tới người khác',
    danh_gia_tieu_cuc_trong_tong_so: 'đánh giá tiêu cực trong tổng số',
    phan_hoi_ly_do: 'phản hổi lý do',
    thoi_gian_co_hieu_qua_cao: 'Thời gian có hiệu quả cao/thấp nhất',
    gio_tot_nhat: 'Giờ tốt nhất là : 4:00 PM',
    gio_thap_nhat: 'Giờ thấp nhất là : 10:00 AM',
    ngay_tot_nhat: 'Ngày tốt nhất là : 15/10/2018',
    ngay_thap_nhat: 'Ngày thấp nhất là : 25/10/2018',
    nhom_cac_diem_co_ket_qua_tot_nhat: 'Nhóm các điểm có kết quả tốt nhất',
    nhom_cac_diem_co_ket_qua_thap_nhat: 'Nhóm các điểm có kết quả thấp nhất',
    ly_do_khach_hang_kem_hai_long_nhat: 'Lý do khách hàng kém hài lòng nhất',
    nhom_cac_diem_co_so_luong_danh_gia_nhieu_nhat: 'Nhóm các điểm có số lượng đánh giá nhiều nhất',
    can_no_luc: 'Cần nỗ lực',
    can_co_gang: 'Cần cố gắng',
    tot: 'Tốt',
    xuat_sac: 'Xuất Sắc',
    chat_luong_dich_vu_cua_ban_da_giam_xuong_dang_ke: 'chất lượng dịch vụ của bạn đã giảm xuống đáng kể',
    chat_luong_dich_vu_cua_ban_da_giam_xuong: 'chất lượng dịch vụ của bạn đã giảm xuống',
    chat_luong_dich_vu_cua_ban_duoc_cai_thien: 'chất lượng dịch vụ của bạn đã được cải thiện',
    chat_luong_dich_vu_cua_ban_duoc_cai_thien_dang_ke: 'chất lượng dịch vụ của bạn đã được cải thiện đáng kể',
    luong_khach_hang_quang_ba_hinh_anh_giam: 'lượng khách hàng quảng bá hình ảnh của bạn đã giảm xuống đáng kể',
    luong_khach_hang_quang_ba_hinh_anh_da_giam: 'lượng khách hàng quảng bá hình ảnh của bạn đã giảm xuống',
    luong_khach_hang_quang_ba_hinh_anh_tang: 'lượng khách hàng quảng bá hình ảnh của bạn tăng lên',
    luong_khach_hang_quang_ba_hinh_anh_tang_len_dang_ke: 'lượng khách hàng quảng bá hình ảnh của bạn tăng lên đáng kể',
    bieu_do_phan_tich: 'Biểu đồ phân tích',
    nguoi_nhan: 'Người nhận',
    lich: 'Lịch:',
    tat_ca: 'Tất cả:',
    to_chuc: 'Tổ chức:',
    xu_ly: 'Xử lý:',
    cong: 'Cổng',
    may_chu_thu: 'Máy chủ thư gửi đi (SMTP)',
    ban_chua_nhap_may_chu: 'Bạn chưa nhập máy chủ',
    ban_chua_nhap_cong: 'Bạn chưa nhập cổng',
    ban_chua_nhap_nguoi_gui: 'Bạn chưa nhập tên người gửi',
    ban_chua_nhap_ho_ten: 'Bạn chưa nhập họ tên',
    ban_chua_nhap_email: 'Bạn chưa nhập email',
    ban_chua_nhap_mat_khau: 'Bạn chưa nhập mật khẩu',
    ten_nguoi_gui: 'Tên người gửi',
    mat_khau: 'Mật khẩu',
    luu_cau_hinh: 'Lưu cấu hình',
    kiem_tra_gui_mail: 'Kiểm tra gửi mail',
    trang_thai: 'Trạng thái',
    lich_bang: 'Lịch',
    cap_nhat: 'Cập nhật',
    co: 'Có',
    khong: 'Không',
    nhan_bao_cao: 'Nhận báo cáo',
    cau_hinh_gui_mail: 'Cấu hình gửi mail',
    xoa: 'Xóa',
    ban_co_chac_chan_muon_xoa: 'Bạn có chắc chắn muốn xóa?',
    xac_nhan_xoa: 'Bạn có thật sự muốn xóa bản ghi? Tiến trình này sẽ không thể dừng lại',
    gui_mail: 'Gửi mail',
    ban_chua_nhap_noi_dung: 'Bạn chưa nhập nội dung',
    noi_dung: 'Nội dung',
    ban_chua_nhap_tieu_de: 'Bạn chưa nhập tiêu đề',
    tieu_de: 'Tiêu đề',
    cau_hinh_se_mac_dinh: 'Cấu hình mail sẽ mặc định, nếu bạn chưa cập nhật mới!',
    bao_cao_ngay_toolip: 'Báo cáo Ngày được gửi lúc 8:00 AM ngày hôm sau!',
    bao_cao_tuan_toolip: 'Báo cáo Tuần được gửi lúc 24:00 Chủ Nhật hàng tuần!',
    bao_cao_thang_toolip: 'Báo cáo Tháng được gửi lúc 24:00 ngày cuối của tháng!',
    danh_sach_thiet_bi: 'Danh sách thiết bị',
    top_cua_hang_mua_sam: 'Biểu đồ lượng khách hàng tại các cửa',
    tat_ca_bieu_do: 'Tất Cả',
    gioi_tinh: 'Giới tính',
    nam_bieu_do: 'Nam',
    nu_bieu_do: 'Nữ',
    do_tuoi_bieu_do: 'Độ tuổi:',
    so_luong: 'Số lượng',
    nhom: 'Nhóm:',
    dia_diem_bieu_do: 'Địa điểm:',
    // fba
    luot_bieu_do: ' lượt',
    phut_bieu_do: ' phút',
    dia_diem: 'Địa điểm',
    dia_diem__: 'Địa điểm',
    // tslint:disable-next-line: max-line-length
    slug_login1: 'Chào mừng đến với hệ thống ACS Analytics. Hệ thống thu thập tất cả các thông tin suốt thời gian trải nghiệm của khách từ khi đặt chân tới khi hoàn thành mua sắm.',
    // tslint:disable-next-line: max-line-length
    slug_login2: 'ACS Analytics thực hiện việc phân tích nâng cao, cung cấp bức tranh toàn cảnh về hoạt động và hiệu quả hoạt động của từng điểm, toàn hệ thống. Đồng thời đưa ra các cảnh báo theo thời gian thực, giúp Ban lãnh đạo và quản lý đưa ra các quyết định nhanh chóng.',
    mot_san_pham_login: 'Một sản phẩm của',
    cau_hinh_mail_bao_cao: 'Cấu hình mail báo cáo',
    nhom_chi_so: 'Nhóm chỉ số',
    nhom__: 'Nhóm',
    dang_tai_du_lieu: 'Đang tải dữ liệu...',
    dang_xu_ly_du_lieu: 'Đang xử lý dữ liệu...',
    dang_tai_cau_hinh: 'Đang tải cấu hình...',
    dang_tai_thong_tin_to_chuc: 'Đang tải thông tin tổ chức...',
    dang_xuat_bao_cao: 'Đang xuất báo cáo...',
    dang_tai_cau_hoi: 'Đang tải câu hỏi...',
    // 19/09/2019
    fba_tu: 'Từ',
    fba_den: 'đến',
    fba_dang_cap_nhat: 'Đang cập nhật',
    // Menu trái
    tro_giup: 'Trợ giúp',
    pho_bien: 'Phổ biến',
    truy_cap_danh_sach: 'Truy cập danh sách trợ giúp',
    them_moi_tro_giup: 'Thêm mới trợ giúp',
    chua_co_bai_viet_huong_dan_nao: 'Chưa có bài viết hướng dẫn nào',
    chua_co_tom_tat_noi_dung: 'Chưa có tóm tắt nội dung',
    trong_bai_viet_nay: 'Trong bài viết này.',
    truy_cap_danh_sach_tro_giup: 'Truy cập danh sách trợ giúp',
    xoa_tat_ca: 'Xóa tất cả',
    chi_tiet_thong_bao: 'Chi tiết thông báo',
    thong_bao: 'thông báo',
    chua_co_thong_bao: 'Chưa có thông báo',
    gui: 'Gửi',
    thay_doi_mat_khau: 'Thay đổi mật khẩu',
    mat_khau_hien_tai: 'Mật khẩu hiện tại',
    mat_khau_moi: 'Mật khẩu mới',
    tuy_chon_: '(tùy chọn)',
    hinh_anh: 'Hình ảnh',
    gui_phan_hoi: 'Gửi phản hồi',
    dang_xuat: 'Đăng xuất',
    ten_nguoi_dung: 'Tên người dùng: ',
    khong_to_chuc: 'Không tổ chức',
    thong_bao_: 'Thông báo: ',
    bao_cao: 'Báo cáo ',
    // help
    trang_chu: 'Trang chủ',
    them_moi: 'Thêm mới',
    mo_ta_chung: 'Mô tả chung',
    duoi45_tu: '(dưới 450 từ)',
    so_tu_hien_tai_: 'Số từ hiện tại:',
    noi_dung_hien_tai: 'Nội dung hiện tại',
    gan_the: 'Gắn thẻ',
    them_gan_the: 'Thêm gắn thẻ',
    quay_lai: 'Quay lại',
    chi_tiet_cau_hoi_va_tro_giup: 'Chi tiết câu hỏi và trợ giúp',
    khong_tim_thay_bai_viet: 'Không tìm thấy bài viết',
    tac_gia_bai_viet_: 'Tác giả bài viết: ',
    ngay_cap_nhat_: 'Ngày cập nhật: ',
    trang_thai_: 'Trạng thái: ',
    luot_xem_: 'Lượt xem: ',
    chia_se: 'Chia sẻ',
    the_tag: 'Thẻ tags',
    the_tag_so: 'Thẻ tag số',
    sua_thong_tin: 'Sửa thông tin',
    sua_thong_tin_tro_giup: 'Sửa thông tin trợ giúp',
    cau_hoi_va_tro_giup: 'Câu hỏi và trợ giúp',
    ket_qua_loc_theo_: 'Kết quả lọc theo: ',
    _tat_ca: 'Tất cả ',
    chua_co_bai_viet_tro_giup: 'Chưa có bài viết trợ giúp',
    // admin fba
    bang_setting: 'Bảng Setting',
    man_hinh_logo: 'Màn hình logo',
    man_hinh_ly_do: 'Màn hình lý do',
    man_hinh_thong_tin_khach_hang: 'Màn hình thông tin khách hàng',
    man_hinh_ket_thuc: 'Màn hình kết thúc',
    so_thu_tu: 'Số thứ tự',
    logo_cong_ty: 'Logo công ty',
    logo_ung_dung: 'Logo ứng dụng',
    them_moi_thanh_cong: 'Thêm mới thành công',
    loi_xu_ly_server: 'Lỗi xử lý bên Server',
    ban_da_xoa_thanh_cong: 'Bạn đã xóa thành công',
    co_loi_xay_ra: 'Có lỗi xảy ra',
    ban_chua_chon_anh_logo_ung_dung: 'Bạn chưa chọn ảnh cho logo ứng dụng',
    ban_chua_chon_anh_logo_cong_ty: 'Bạn chưa chọn ảnh cho logo công ty',
    tiep_theo: 'Tiếp theo',
    tieu_de_send: 'Tiêu đề nút send',
    tieu_de_cancel: 'Tiêu đề nút cancel',
    tieu_de_khung_ly_do: 'Tiêu đề khung lý do',
    tieu_de_ly_do: 'Tiêu đề lý do',
    tieu_de_goi_y: 'Tiêu đề gợi ý lý do',
    ban_chua_nhap_tieu_de_nut_gui: ' Bạn chưa nhập thông tin nút send',
    ban_chua_nhap_tieu_de_nut_cancel: 'Bạn chưa nhập thông tin nút cancel',
    ban_chua_nhap_khung_ly_do: 'Bạn chưa nhập thông tin khung lý do',
    ban_chua_nhap_goi_y_ly_do: 'Bạn chưa nhập gợi ý lý do',
    ban_chua_nhap_tieu_de_ly_do: 'Bạn chưa nhập tiêu đề lý do',
    tieu_de_thong_tin_khach_hang: 'Tiêu đề khung thông tin khách hàng',
    ban_chua_nhap_tieu_de_thong_tin_khach_hang: 'Bạn chưa nhập tiêu đề khung thông tin khách hàng',
    tieu_de_khung_ten_kh: 'Tiêu đề khung tên khách hàng',
    ban_chua_nhap_tieu_de_khung_ten_kh: 'Bạn chưa nhập tiêu đề khung tên khách hàng',
    tieu_de_khung_email_kh: 'Tiêu đề khung email khách hàng',
    ban_chua_nhap_tieu_de_khung_email_kh: 'Bạn chưa nhập tiêu đề khung email khách hàng',
    tieu_de_khung_sdt_kh: 'Tiêu đề khung số điện thoại khách hàng',
    ban_chua_nhap_tieu_de_khung_sdt_kh: 'Bạn chưa nhập tiêu đề khung số điện thoại khách hàng',
    yeu_cau_tu_chon: 'Yêu cầu tùy chọn',
    yeu_cau_ten_khach_hang: 'Yêu cầu tên khách hàng',
    yeu_cau_email_khach_hang: 'Yêu cầu email khách hàng',
    yeu_cau_sdt_khach_hang: 'Yêu cầu số điện thoại khách hàng',
    thong_diep_ket_thuc: 'Thông điệp kết thúc',
    ban_chua_nhap_thong_diep_ket_thuc: 'Bạn chưa nhập thông điệp kết thúc ',
    xac_nhan: 'Xác nhận',
    sua_thong_tin_thanh_cong: 'Sửa thông tin thành công',
    // default fba
    xin_cam_on: 'Xin cảm ơn!',
    hen_gap_lai_quy_khach: 'Hẹn gặp lại quý khách',
    so_dien_thoai: 'Số điện thoại',
    ho_ten: 'Họ tên',
    vui_long_cung_cap_thong_tin: 'Vui lòng cung cấp thông tin!',
    ly_do_quy_vi_hai_long: 'Lý do quý vị chưa hài lòng',
    moi_nhap_ly_do: 'Mời nhập lý do!',
    ban_muon_gop_y_them: 'Bạn muốn góp ý thêm?',
    cancel: 'Bỏ qua',
    mac_dinh: 'Mặc định',
    them_cau_hoi: 'Thêm câu hỏi',
    ten_to_chuc: 'Tổ chức',
    tinh_trang_to_chuc: 'Tình trạng câu hỏi',
    trang_thai_cau_hoi: 'Trạng thái câu hỏi',
    danh_sach_cau_hoi: 'Danh sách câu hỏi',
    chien_dich: 'Chiến dịch',
    ten_cau_hoi: 'Tên câu hỏi',
    thoi_gian_bat_dau: 'Thời gian bắt đầu',
    thoi_gian_ket_thuc: 'Thời gian kết thúc',
    ban_chua_nhap_thoi_gian: 'Bạn chưa nhập thời gian',
    xu_ly_: 'Xử lý',
    bat: 'Bật',
    tat: 'Tắt',
    ban_co_chac_chan_khoi_phuc: 'Bạn có chắc chắn muốn khôi phục?',
    xac_nhan_khoi_phuc: 'Bạn có thật sự muốn khôi phục bản ghi? Tiến trình này sẽ không thể dừng lại',
    khoi_phuc: 'Khôi phục',
    cau_hoi: 'Câu hỏi',
    ly_do: 'Lý do',
    ly_do_khac: 'Lý do khác',
    ban_chua_chon_to_chuc: 'Bạn chưa chọn tổ chức',
    chon_cau_hoi: 'Chọn câu hỏi',
    vui_long_chon_cau_hoi: '--Vui lòng chọn câu hỏi--',
    ban_chua_nhap_cau_hoi: 'Bạn chưa nhập câu hỏi',
    ban_chua_nhap_chien_dich: 'Bạn chưa nhập chiến dịch',
    kich_hoat: 'Kích hoạt',
    nhan: 'Nhãn',
    chat_luong_cau_hoi: 'Chất lượng câu hỏi',
    man_hinh_chinh: 'Màn hình chính',
    thong_tin_khach_hang: 'Thông tin khách hàng',
    xin_cam_on_: 'Xin cảm ơn',
    anh_ly_do: 'Ảnh lý do',
    ten_ly_do: 'Tên lý do',
    nhap_ly_do_: 'Nhập lý do...',
    cap_nhat_cau_hoi: 'Cập nhật câu hỏi',
    mot_thiet_bi: 'Một thiết bị',
    thiet_bi: 'Thiết bị ',
    vua_cam_sac: ' vừa cắm sạc',
    thay_doi_ket_noi: ' thay đổi kết nối',
    da_tat: ' đã tắt',
    giam_sat_thiet_bi: 'Giám sát thiết bị',
    ten_thiet_bi: 'Tên thiết bị',
    vi_tri: 'Vị trí',
    dung_luong_trong: 'Dung lượng trống',
    pin: 'Pin ',
    nguon: 'Nguồn',
    mang: 'Mạng',
    dang_online: 'đang online',
    them_thiet_bi: 'Thêm thiết bị',
    cap_nhat_thiet_bi: 'Cập nhật thiết bị',
    ban_chua_nhap_serial_number: 'Bạn chưa nhập serial number',
    ban_chua_chon_vi_tri: 'Bạn chưa chọn vị trí',
    dieu_kien_gui_thong_bao: 'Điều kiện gửi thông báo',
    bang: 'Bảng',
    thong_tin_cau_hinh: 'Thông tin cấu hình',
    phan_tram_: 'phần trăm',
    thoi_gian_gui_thong_bao: 'Thời gian gửi thông báo',
    gio_: 'giờ',
    them_cau_hinh: 'Thêm cấu hình',
    sua_cau_hinh: 'Sửa cấu hình',
    chi_so_dat_dieu_kien_gui: 'Chỉ số đạt điều kiện gửi thông báo',
    ban_chua_nhap_chi_so: 'Bạn chưa nhập chỉ số đạt điều kiện gửi thông báo',
    khoang_thoi_gian_xu_ly: 'Khoảng thời gian xử lý điều kiện',
    don_vi_tieng: 'đơn vị tiếng',
    mo_hinh_boston: 'Mô hình Boston',
    nhap_du_lieu_thanh_cong: 'Nhập dữ liệu thành công',
    file_khong_dung_dinh_dang: 'File không đúng định dạng',
    vui_long_chon_cua_hang: 'Vui lòng chọn cửa hàng phù hợp!',
    qua_trinh_nhap_du_lieu: 'Quá trình nhập dữ liệu có thể bị ghi đè.\nBạn có chắc chắn muốn lưu vào hệ thống?',
    vui_long_chon_file: 'Vui lòng chọn file!',
    qua_trinh_export: 'Quá trình export file bị lỗi. Vui lòng thử lại!',
    tat_ca_: 'Tất cả',
    anh_kich_thuoc: 'Ảnh kích thước không vượt quá',
    phan_hoi: 'Phản Hồi',
    huong_dan_su_dung: 'Hướng dẫn sử dụng',
    khong_the_ket_noi: 'Không thể kết nối máy chủ',
    vui_long: 'Vui lòng bấm',
    bam_vao_day: 'vào đây',
    de_tai_lai: 'để tải lại!',
    Thong_bao: 'Thông báo!',
    Tai_lai: 'Tải lại',
    Co_loi_xay_ra_Vui_long_nhan: 'Có lỗi xảy ra! Vui lòng nhấn',
    Bao_cao_khach_hang_ngay: 'Báo cáo khách hàng ngày',
    Bao_cao_khach_hang_thang: 'Báo cáo khách hàng tháng',
    Bao_cao_khach_hang_nam: 'Báo cáo khách hàng năm',
    Bao_cao_khach_hang_theo_loi_vao: 'Báo cáo khách hàng theo lối vào',
    Ban_do_nhiet_heatmap: 'Bản đồ nhiệt Heatmap',
    // Crescent Mall
    Subtotal: 'Tổng phụ',
    ToTal: 'Tổng',
    In: 'Vào',
    Out: 'Ra',
    Average_of_In_and_Out: 'Trung bình vào và ra',
    Rank_of_In_and_Out: 'Xếp hạng vào và ra',
    Number_of_eletric_counter: 'Mã thiết bị',
    Time: 'Thời gian',
    FLOOR: 'Tầng',
    Daily: 'Hàng ngày',
    Month: 'Tháng',
    dang_nhap: 'Đăng nhập',
    quen_mat_khau: 'Quên mật khẩu?',
    vui_long_nhap_email: 'Vui lòng nhập email của bạn!',
    quay_lai_dang_nhap: 'Quay lại đăng nhập!',
    dang_nhap_vao_tai_khoan_cua_ban: 'Đăng nhập vào tài khoản của bạn',
    VND: 'VNĐ',
    Percent: ' Phần trăm (%)',
    min: ' (phút)',
    Compared: ' được so sánh',
};
