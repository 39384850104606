export const language_en = {
    nhap_du_lieu: 'Data input',
    tai_file_mau: 'Sample template file dowload',
    nhap_du_lieu_thanh_menu: 'Data input',
    chon_cua_hang: 'Select store',
    time: 'Time',
    chon_cho_so: 'select metrics',
    chon_to_chuc: 'Select organization',
    ghi_de: 'Overweite:',
    dang_xu_ly: ' Processing',
    da_cap_nhat: 'Update sucessfully',
    ban_ghi: 'Records ',
    ban_ghi_loi: 'Record update error ',
    content_tochuc: 'Pls select your organization!',
    content_cuahang: 'Pls select your store! ',
    content_thoigian: 'Pls select start time, end time to update data!',
    content_chiso: 'Pls select your metrics!',
    content_file: 'Pls select your files ! Shohuld use standard template files to get the best performance!',
    content_ghide: 'Pls select the metrics you want to orvewrite. The metrics will overwrite on old metrics with the same time!',
    popup_nhap_du_lieu: 'Input data ! Do you want to import data ? After you Input data you could not see your old data?',
    bo_qua: ' Ignore',
    chap_nhan: 'Accept',
    vao: ' enter',
    va: 'and',
    chon_dia_diem: 'Select your store',
    chon_thoi_ky: 'Select time period',
    tim_kiem_dia_diem: 'Search store/group',
    bao_cao_duoc_tao: 'This report was created',
    lam_moi_bao_cao: 'Refresh your report',
    gia_tri_trung_binh_cua_hang: 'Value average of stores',
    thoi_gian_trung_binh: 'Avg time',
    thoi_gian_chenh_lech: 'Time gap',
    thoi_gian_danh_gia: 'Evaluation time',
    ap_dung: ' Apply',
    dong: 'Close',
    xuat: 'Export',
    thoi_gian: 'Time',
    xem_theo: 'View by',
    Xem_theo: 'View by',
    xem: 'View ',
    so_voi: 'Compared to',
    So_voi: 'Compared to',
    lan: 'times)',
    tuong_duong: 'Balance',
    luc: 'at',
    gio: 'Hour',
    ngay: 'Day',
    tuan: 'Week',
    thang: 'Month',
    nam: 'Year',
    phut: 'Munite',
    mot_phan_tu_gio: 'Quarter Hour',
    nua_gio: 'Half Hour',
    ngay_lam_viec: 'Workdays',
    ngay_lam_viec_cuoi_tuan: 'Workday, weekend',
    dong_cua_so: 'Close',
    gio_mo_cua: 'Open Time:',
    gioi_tinh_gender: 'Gender',
    do_tuoi_age: 'Age range',
    dia_diem_khu_vuc: 'Location',
    thoi_ky: 'Period time',
    trong_tong_so: 'of total',
    age: 'Age range',
    nu_female: 'Female',
    nam_male: 'Male',
    khong_xac_dinh: 'Unknown',
    cau_hinh: 'Configuration',
    hieu_qua_hoat_dong: 'Operation Performance',
    cua_hang: 'Store',
    xuat_bao_cao: 'Report export',
    xuat_ra_excel: 'Export to Excel',
    ngay_bay_gio: 'Live',
    so_voi_ngay_hom_qua: 'Compared to Yesterday',
    khach_hang_dang_mua_sam: 'Number of shoppers in store',
    so_luong_khach_hien_tai: 'Number of shoppers up to now',
    // tong_khach_vao: 'Total shopper enter',
    // tong_khach_ra: 'Total shopper exit',
    tong_khach_vao: 'Total visitors in',
    tong_khach_ra: 'Total visittors out',
    cau_hoi_khao_sat: 'Survey question',
    dang_dien_ra: 'Ongoing',
    sap_dien_ra: 'Upcoming',
    ket_thuc: 'Ending',
    so_dong: 'Show: ',
    tim_kiem: 'Search:',
    STT: 'STT',
    ho_va_ten: 'Full name',
    dien_thoai: 'Mobile number',
    danh_gia: 'Responses',
    ly_do_khong_hai_long: 'the reasons made shoppers unhappy ',
    y_kien_bo_sung: 'Open comments',
    khong_co_du_lieu: 'No data',
    hien_thi_theo: 'View by:',
    phan_tram: 'Percentage',
    tong_so_danh_gia: 'Total responses',
    danh_gia_bang: 'responses',
    muc_do_khach_hang_dat_duoc: 'the performance you reach',
    khach_hang_san_sang_gioi_thieu: 'shoppers are ready to introduce your service to their friends',
    danh_gia_tieu_cuc_trong_tong_so: 'unhappy factores of total',
    phan_hoi_ly_do: 'Let you know their unhappy factors',
    thoi_gian_co_hieu_qua_cao: 'The time to get the best/worst performance',
    gio_tot_nhat: 'The best time : 4:00 PM',
    gio_thap_nhat: 'The worst time : 10:00 AM',
    ngay_tot_nhat: 'The best day : 15/10/2018',
    ngay_thap_nhat: 'The worst day : 25/10/2018',
    nhom_cac_diem_co_ket_qua_tot_nhat: 'The best performance stores, top down',
    nhom_cac_diem_co_ket_qua_thap_nhat: 'The worst performance stores, top down',
    ly_do_khach_hang_kem_hai_long_nhat: 'The factors make shopper much unhappy',
    nhom_cac_diem_co_so_luong_danh_gia_nhieu_nhat: 'The stores have the most responses',
    can_no_luc: 'Need more improvement',
    can_co_gang: 'Try more',
    tot: 'Good',
    xuat_sac: 'Excellent',
    chat_luong_dich_vu_cua_ban_da_giam_xuong_dang_ke: 'your service quality is going down significantly',
    chat_luong_dich_vu_cua_ban_da_giam_xuong: 'your service quality is going down',
    chat_luong_dich_vu_cua_ban_duoc_cai_thien: 'your service quality is impoved ! Go ahead',
    chat_luong_dich_vu_cua_ban_duoc_cai_thien_dang_ke: 'your service quality is going up significantly ! Go ahead',
    luong_khach_hang_quang_ba_hinh_anh_giam: 'Your brand is going down in your shoppers mind significantly',
    luong_khach_hang_quang_ba_hinh_anh_da_giam: 'Your brand is going down in your shoppers mind',
    // tslint:disable-next-line: max-line-length
    luong_khach_hang_quang_ba_hinh_anh_tang: 'Your brand is going up in your shoppers mind and more shoppers will bring their friends to you',
    // tslint:disable-next-line: max-line-length
    luong_khach_hang_quang_ba_hinh_anh_tang_len_dang_ke: 'Your brand is going up in your shoppers mind significantly and more shoppers will bring their friends to you',
    bieu_do_phan_tich: 'Analysis chart',
    nguoi_nhan: 'Recipient',
    lich: 'Schedule:',
    tat_ca: 'All:',
    to_chuc: 'Organization:',
    xu_ly: 'Process:',
    cong: 'Port',
    may_chu_thu: 'sent email server (SMTP)',
    ban_chua_nhap_may_chu: 'you did not import server',
    ban_chua_nhap_cong: 'you did not import port',
    ban_chua_nhap_nguoi_gui: 'You did not import sender name',
    ban_chua_nhap_ho_ten: 'You did not import full name',
    ban_chua_nhap_email: 'You did not import email',
    ban_chua_nhap_mat_khau: 'You did not import password',
    ten_nguoi_gui: 'Sender name',
    mat_khau: 'Password',
    luu_cau_hinh: 'save',
    kiem_tra_gui_mail: 'Check email sent',
    trang_thai: 'Status',
    lich_bang: 'Schedule',
    cap_nhat: 'Update',
    co: 'Yes',
    khong: 'No',
    nhan_bao_cao: 'Report receipt',
    cau_hinh_gui_mail: 'Set up to send mail',
    xoa: 'Delete',
    ban_co_chac_chan_muon_xoa: 'Are you sure to delete?',
    xac_nhan_xoa: 'Are you sure to delete record? You could not restore after you delete',
    gui_mail: 'Send mail',
    ban_chua_nhap_noi_dung: 'You did not import content',
    noi_dung: 'Content',
    ban_chua_nhap_tieu_de: 'You did not import title',
    tieu_de: 'Title',
    cau_hinh_se_mac_dinh: 'If you do not update new report email schedule the report will send by default schedule!',
    bao_cao_ngay_toolip: 'Daily report will send at 8:00 AM in nexd day!',
    bao_cao_tuan_toolip: 'Weekly report will send at 24:00 in Sunday!',
    bao_cao_thang_toolip: 'Monthly report will send at 24:00 in the last day of the month!',
    danh_sach_thiet_bi: 'List of devices',
    top_cua_hang_mua_sam: 'Chart of customers at the stores',
    tat_ca_bieu_do: 'All',
    gioi_tinh: 'Gender',
    nam_bieu_do: 'Male',
    nu_bieu_do: 'Female',
    do_tuoi_bieu_do: 'Age range:',
    so_luong: 'Quantity',
    nhom: 'Group:',
    dia_diem_bieu_do: 'Location:',
    // fba
    luot_bieu_do: ' responses',
    phut_bieu_do: ' minutes',
    dia_diem: 'Locations',
    dia_diem__: 'Place',
    // tslint:disable-next-line: max-line-length
    slug_login1: 'Welcome to ACS Analytics Platform. The first IoT technology integration platform in Vietnam to bring e-commerce style shopper analytics to brick-and-mortar retailers.',
    // tslint:disable-next-line: max-line-length
    slug_login2: 'ACS Solutions ACS Analytics solution is a pioneering solution to focus entirely on optimizing the shopper experience.ACS Analytics is being used by Top 500 leading retail and service businesses in Vietnam to increase sales, prevent theft and eliminate unnecessary costs..',
    mot_san_pham_login: 'A service of ',
    cau_hinh_mail_bao_cao: 'Report mail configuration',
    nhom_chi_so: 'Metric group',
    nhom__: 'Group',
    dang_tai_du_lieu: 'Data dowloading...',
    dang_xu_ly_du_lieu: 'Data processing...',
    dang_tai_cau_hinh: 'Configuration processing...',
    dang_tai_thong_tin_to_chuc: 'Loading organization information...',
    dang_xuat_bao_cao: 'Exporting report...',
    dang_tai_cau_hoi: 'Loading questions...',
    // 19/09/2019
    fba_tu: 'From',
    fba_den: 'to',
    fba_dang_cap_nhat: 'Updating',
    tro_giup: 'Help',
    pho_bien: 'Popular',
    truy_cap_danh_sach: 'Visit the help list',
    them_moi_tro_giup: 'New help',
    chua_co_bai_viet_huong_dan_nao: 'No tutorial articles',
    chua_co_tom_tat_noi_dung: 'No summary of the content',
    trong_bai_viet_nay: 'In this article.',
    truy_cap_danh_sach_tro_giup: 'Access the help list',
    xoa_tat_ca: 'Delete all',
    chi_tiet_thong_bao: 'Notice details',
    thong_bao: 'notification',
    chua_co_thong_bao: 'No notification',
    gui: 'Send',
    thay_doi_mat_khau: 'Change the password',
    mat_khau_hien_tai: 'Current Password',
    mat_khau_moi: 'New Password',
    tuy_chon_: '(option)',
    hinh_anh: 'Image',
    gui_phan_hoi: 'Send feedback',
    dang_xuat: 'Logout',
    ten_nguoi_dung: 'Username: ',
    khong_to_chuc: 'No oragnization',
    thong_bao_: 'Notification: ',
    bao_cao: 'Report ',
    // help
    trang_chu: 'Home',
    them_moi: 'Add new',
    mo_ta_chung: 'General description',
    duoi45_tu: '(less than 450 words)',
    so_tu_hien_tai_: 'Current word count:',
    noi_dung_hien_tai: 'Current content',
    gan_the: 'Tag',
    them_gan_the: 'Add tag',
    quay_lai: 'Back',
    chi_tiet_cau_hoi_va_tro_giup: 'Detailed questions and help',
    khong_tim_thay_bai_viet: 'Not find the article',
    tac_gia_bai_viet_: 'Article author: ',
    ngay_cap_nhat_: 'Update day: ',
    trang_thai_: 'Status: ',
    luot_xem_: 'View: ',
    chia_se: 'Share',
    the_tag: 'Tags',
    the_tag_so: 'Number tag',
    sua_thong_tin: 'Correct the information',
    sua_thong_tin_tro_giup: 'Edit help information',
    cau_hoi_va_tro_giup: 'Questions and help',
    ket_qua_loc_theo_: 'Filter results by: ',
    _tat_ca: 'All ',
    chua_co_bai_viet_tro_giup: 'No help articles',
    // admin fba
    bang_setting: 'Table Setting',
    man_hinh_logo: 'Logo screen',
    man_hinh_ly_do: 'Reason screen',
    man_hinh_thong_tin_khach_hang: 'Customer information screen',
    man_hinh_ket_thuc: 'The screen ends',
    so_thu_tu: 'No.',
    logo_cong_ty: 'Company logo',
    logo_ung_dung: 'Application logo',
    them_moi_thanh_cong: 'Add successfully',
    loi_xu_ly_server: 'Error processing server side',
    ban_da_xoa_thanh_cong: 'You have successfully deleted',
    co_loi_xay_ra: 'An error occurred',
    ban_chua_chon_anh_logo_ung_dung: 'You have not selected an image for the application logo',
    ban_chua_chon_anh_logo_cong_ty: 'You have not selected a photo for your company logo',
    tiep_theo: 'Next',
    tieu_de_send: 'Title send',
    tieu_de_cancel: 'Title cancel ',
    tieu_de_khung_ly_do: 'Reason frame title',
    tieu_de_ly_do: 'Title reason',
    tieu_de_goi_y: 'Title suggests reason',
    ban_chua_nhap_tieu_de_nut_gui: 'You have not entered the send button information',
    ban_chua_nhap_tieu_de_nut_cancel: 'You have not entered the cancel button information',
    ban_chua_nhap_khung_ly_do: 'You have not entered the reasoning frame information',
    ban_chua_nhap_goi_y_ly_do: 'You have not entered a reason hint',
    ban_chua_nhap_tieu_de_ly_do: 'You have not entered a reason title',
    tieu_de_thong_tin_khach_hang: 'Customer information frame title',
    ban_chua_nhap_tieu_de_thong_tin_khach_hang: 'You have not entered the title of the customer information frame',
    tieu_de_khung_ten_kh: 'Title frame customer name',
    ban_chua_nhap_tieu_de_khung_ten_kh: 'You have not entered the title box of the customer name',
    tieu_de_khung_email_kh: 'Customer email header',
    ban_chua_nhap_tieu_de_khung_email_kh: 'You did not enter the email client title',
    tieu_de_khung_sdt_kh: 'Title frame phone number customer',
    ban_chua_nhap_tieu_de_khung_sdt_kh: 'You have not entered the title of the customer phone number frame',
    yeu_cau_tu_chon: 'Required option',
    yeu_cau_ten_khach_hang: 'Request a customer name',
    yeu_cau_email_khach_hang: 'Customer email required',
    yeu_cau_sdt_khach_hang: 'Request a customer phone number',
    thong_diep_ket_thuc: 'The end message',
    ban_chua_nhap_thong_diep_ket_thuc: 'You did not enter a finish message ',
    xac_nhan: 'Submit',
    sua_thong_tin_thanh_cong: 'Corrected information successfully',
    // default fba
    xin_cam_on: 'Thank you!',
    hen_gap_lai_quy_khach: 'See you later',
    so_dien_thoai: 'Telephone number',
    ho_ten: 'Full name',
    vui_long_cung_cap_thong_tin: 'Please provide information!',
    ly_do_quy_vi_hai_long: 'The reason you are not satisfied',
    moi_nhap_ly_do: 'Please enter a reason!',
    ban_muon_gop_y_them: 'Want more suggestions?',
    cancel: 'Cancel',
    mac_dinh: 'Default',
    them_cau_hoi: 'Add questions',
    ten_to_chuc: 'Organization',
    tinh_trang_to_chuc: 'Status question',
    trang_thai_cau_hoi: 'Status question',
    danh_sach_cau_hoi: 'Question list',
    chien_dich: 'Campaign',
    ten_cau_hoi: 'Question name',
    thoi_gian_bat_dau: 'Start time',
    thoi_gian_ket_thuc: 'End time',
    xu_ly_: 'Process',
    bat: 'On',
    tat: 'Off',
    ban_co_chac_chan_khoi_phuc: 'Are you sure you want to restore?',
    xac_nhan_khoi_phuc: 'Do you really want to recover records? This process will not be stopped',
    khoi_phuc: 'Restore',
    cau_hoi: 'Question',
    ly_do: 'Reason',
    ly_do_khac: 'Other reason',
    ban_chua_chon_to_chuc: 'You have not selected an organization',
    chon_cau_hoi: 'Choose a question',
    vui_long_chon_cau_hoi: '--Please select a question--',
    ban_chua_nhap_cau_hoi: 'You have not entered the question',
    ban_chua_nhap_chien_dich: 'You have not entered a campaign',
    kich_hoat: 'Actived',
    nhan: 'Label',
    chat_luong_cau_hoi: 'Quality questions',
    man_hinh_chinh: 'Home screen',
    thong_tin_khach_hang: 'Customer information',
    xin_cam_on_: 'Thank you',
    anh_ly_do: 'Photo of reason',
    ten_ly_do: 'Name of reason',
    nhap_ly_do_: 'Enter a reason...',
    cap_nhat_cau_hoi: 'Updated question',
    mot_thiet_bi: 'A device',
    thiet_bi: 'The device ',
    vua_cam_sac: '  plugged in',
    thay_doi_ket_noi: ' changed connection',
    da_tat: ' disconnected',
    giam_sat_thiet_bi: 'Monitoring device',
    ten_thiet_bi: 'Device name',
    vi_tri: 'Location',
    dung_luong_trong: 'Free space ',
    pin: 'The battery ',
    nguon: 'Power',
    mang: 'Network',
    dang_online: 'Online',
    them_thiet_bi: 'Add device',
    cap_nhat_thiet_bi: 'Device update',
    ban_chua_nhap_serial_number: 'You have not entered the serial number',
    ban_chua_chon_vi_tri: 'You have not selected a location',
    dieu_kien_gui_thong_bao: 'Conditions for sending notices',
    bang: 'Table',
    thong_tin_cau_hinh: 'Configuration information',
    phan_tram_: 'percent',
    thoi_gian_gui_thong_bao: 'Time to send notice',
    gio_: 'hours',
    them_cau_hinh: 'Add configuration',
    sua_cau_hinh: 'Edit configuration',
    chi_so_dat_dieu_kien_gui: 'Indicator qualified to send notice',
    ban_chua_nhap_chi_so: 'You have not entered the eligibility criteria to send notifications',
    khoang_thoi_gian_xu_ly: 'Interval processing time',
    don_vi_tieng: 'hours',
    mo_hinh_boston: 'Boston Cash & Cow Report ',
    nhap_du_lieu_thanh_cong: 'Data entry successful',
    file_khong_dung_dinh_dang: 'File is malformed',
    vui_long_chon_cua_hang: 'Please select the appropriate store!',
    qua_trinh_nhap_du_lieu: 'Data import process may be overwritten. \n Are you sure you want to save to the system? ',
    vui_long_chon_file: 'Please select a file!',
    qua_trinh_export: 'The file export process has failed. Please try again!',
    tat_ca_: 'All',
    anh_kich_thuoc: 'Photo size does not exceed',
    phan_hoi: 'Feedback',
    huong_dan_su_dung: 'Guides',
    khong_the_ket_noi: 'Unable to connect to the server',
    vui_long: 'Please',
    bam_vao_day: 'click here',
    de_tai_lai: 'to reload!',
    Thong_bao: 'Notification!',
    Tai_lai: 'Reload',
    Co_loi_xay_ra_Vui_long_nhan: 'An error occurred! Please click',
    Bao_cao_khach_hang_ngay: 'Daily Traffic Report on',
    Bao_cao_khach_hang_thang: 'Monthly Traffic Report on',
    Bao_cao_khach_hang_nam: 'Yearly Traffic Report on',
    Bao_cao_khach_hang_theo_loi_vao: 'Traffic Report per ebtrance',
    Ban_do_nhiet_heatmap: ' Store Heatmap',
    // Crescent Mall
    Subtotal: 'Subtotal',
    ToTal: 'ToTal',
    In: 'In',
    Out: 'Out',
    Average_of_In_and_Out: 'Average of In and Out',
    Rank_of_In_and_Out: 'Rank of In and Out',
    Number_of_eletric_counter: 'Number of electric counter',
    Time: 'Time',
    FLOOR: 'FLOOR',
    Daily: 'Daily',
    Month: 'Month',
    dang_nhap: 'Login',
    quen_mat_khau: 'Forgot password?',
    vui_long_nhap_email: 'Please type your email!',
    quay_lai_dang_nhap: 'Back to login!',
    dang_nhap_vao_tai_khoan_cua_ban: 'Sign In to your account',
    VND: 'VND',
    Percent: ' Percent (%)',
    min: ' (min)',
    Compared: ' Compared',
};
