import { AdminLanguage } from './index';
export class AdminVnLanguage extends AdminLanguage {
    // Sites
    STT = 'STT';
    danh_sach_dia_diem = ' Danh sách địa điểm';
    dia_diem = 'Địa điểm';
    them_dia_diem = 'Thêm địa điểm';
    cap_nhat_dia_diem = 'Cập nhật địa điểm';
    to_chuc_ = 'Tổ chức:';
    ma = 'Tên';
    ten_viet_tat = 'Tên viết tắt';
    ma_dia_diem = 'Mã địa điểm';
    trang_thai = 'Trạng thái';
    xu_ly = 'Xử lý';
    dong = 'Đóng';
    ban_co_chac_chan_muon_xoa = 'Bạn có chắc chắn muốn xóa?';
    xac_nhan_xoa = 'Bạn có thật sự muốn xóa bản ghi? Tiến trình này sẽ không thể dừng lại';
    them_dia_diem_mac_dinh = 'Thêm địa điểm mặc định';
    to_chuc = 'Tổ chức';
    ten_dia_diem = 'Tên địa điểm';
    ban_chua_nhap_ten_dia_diem = 'Bạn chưa nhập tên địa điểm';
    dia_diem_co_it_nhat_3_ki_tu = 'Địa điểm có ít nhất 3 kí tự';
    dia_diem_co_nhieu_nhat_50_ki_tu = 'Địa điểm có nhiều nhất 50 kí tự';
    ban_chua_nhap_ma_dia_diem = 'Bạn chưa nhập mã địa điểm';
    ma_dia_diem_co_it_nhat_3_ki_tu = 'Mã địa điểm có ít nhất 3 kí tự';
    ma_dia_diem_co_nhieu_nhat_50_ki_tu = 'Mã địa điểm có nhiều nhất 50 kí tự';
    tom_tat = 'Tóm tắt';
    ban_chua_nhap_tom_tat = 'Bạn chưa nhập tóm tắt';
    tom_tat_co_it_nhat_3_ki_tu = 'Tóm tắt có ít nhất 3 kí tự';
    tom_tat_co_nhieu_nhat_50_ki_tu = 'Tóm tắt có nhiều nhất 50 kí tự';
    danh_muc = 'Danh mục';
    thoi_gian_hoat_dong = 'Thời gian hoạt động';
    gio_mo_cua = 'Giờ mở cửa';
    hoat_dong = 'Hoạt động';
    co = 'Có';
    khong = 'Không';
    xoa = 'Xóa';
    them = 'Thêm';
    cap_nhat = 'Cập nhật';
    thuoc_dia_diem = 'Thuộc địa điểm ';
    ban_chua_nhap_gio_mo_cua = 'Bạn chưa nhập giờ mở cửa';
    ban_chua_nhap_gio_dong_cua = 'Bạn chưa nhập giờ đóng cửa';
    yeu_cau_nhap_day_du_thong_tin = '(*) yêu cầu bạn hãy nhập đầy đủ thông tin';
    //  Công ty
    danh_sach_cong_ty = ' Danh sách công ty';
    them_cong_ty = 'Thêm công ty';
    cong_ty = 'Công ty';
    mo_ta = 'Mô tả';
    ngay_tham_gia = 'Ngày tham gia';
    ban_chua_nhap_ten_cong_ty = 'Bạn chưa nhập tên công ty';
    logo_cong_ty = 'Logo công ty';
    ma_cong_ty = 'Mã công ty';
    ban_chua_nhap_ma_cong_ty = 'Bạn chưa nhập mã công ty';
    ban_chua_nhap_ten_viet_tat = 'Bạn chưa nhập tên viết tắt';
    ban_chua_nhap_mo_ta = 'Bạn chưa nhập mô tả';
    ban_chua_nhap_subdomain = 'Bạn chưa nhập subdomain';
    Subdomain = 'Subdomain';
    nguoi_dung_quan_tri = 'Người dùng quản trị';
    ban_chua_nhap_nguoi_quan_tri = 'Bạn chưa nhập người quản trị';
    Email_quan_tri = 'Email quản trị';
    ban_chua_nhap_email = 'Bạn chưa nhập email';
    mat_khau = 'Mật khẩu';
    ban_chua_nhap_mat_khau = 'Bạn chưa nhập mật khẩu';
    Module_su_dung = 'Module sử dụng';
    cap_nhat_cong_ty = 'Cập nhật công ty';
    // Location
    danh_sach_vi_tri = ' Danh sách vị trí';
    tim_kiem_ = 'Tìm kiếm:';
    vi_tri = 'Vị trí';
    ma_vi_tri = 'Mã vị trí';
    them_vi_tri = 'Thêm vị trí';
    khong_co_du_lieu = 'Không có dữ liệu';
    cap_nhat_vi_tri = 'Cập nhật vị trí';
    chua_chon = 'Chưa chọn';
    ban_chua_nhap_vi_tri = 'Bạn chưa nhập vi_tri';
    vi_tri_co_it_nhat_3_ki_tu = 'Vị trí có ít nhất 3 kí tự';
    vi_tri_co_nhieu_nhat_50_ki_tu = 'Vị trí có nhiều nhất 50 kí tự';
    // Category
    danh_sach_danh_muc = ' Danh sách danh mục';
    them_moi = 'Thêm mới';
    ngay_cap_nhat = 'Ngày cập nhật';
    them_danh_muc = 'Thêm danh mục';
    cap_nhat_danh_muc = 'Cập nhật danh mục';
    ma_danh_muc = 'Mã danh mục';
    ban_chua_nhap_danh_muc = 'Bạn chưa nhập danh mục';
    danh_muc_co_it_nhat_3_ki_tu = 'Danh mục có ít nhất 3 kí tự';
    // Dánh sách thiết bị
    danh_sach_thiet_bi = ' Danh sách thiết bị';
    so_dong = 'Số dòng = ';
    chua_cap_nhat = 'Chưa cập nhật';
    them_thiet_bi = 'Thêm thiết bị';
    cap_nhat_thiet_bi = 'Cập nhật thiết bị';
    ban_chua_nhap_seri = 'Bạn chưa nhập serial number';
    serial_number_co_it_nhat_3_ki_tu = 'Serial number có ít nhất 3 kí tự';
    ban_chua_nhap_IP = 'Bạn chưa nhập IP';
    ban_chua_chon_vi_tri = 'Bạn chưa chọn vị trí';
    ten_thiet_bi = 'Tên thiết bị';
    che_do = 'Chế độ';
    dia_chi_IP = 'Địa chi IP';
    dia_chi_wan_ip = 'Địa chỉ WAN IP';
    dang_hoat_dong = 'Đang hoạt động';
    ngat_ket_noi = 'Ngắt kết nối';
    ban_co_chac_chan_muon_khoi_phuc = 'Bạn có chắc chắn muốn khôi phục lại dữ liệu?';
    xin_luu_y = 'Xin hãy lưu ý!! Tiến trình này sẽ không thể dừng lại';
    ban_chua_nhap_ten_thiet_bi = 'Bạn chưa nhập tên thiết bị';
    kich_hoat = 'Kích hoạt';
    dia_chi_mac = 'Địa chỉ MAC';
    cong_http = 'Cổng Http';
    cong_https = 'Cổng Https';
    chon_loc_mien = 'Chọn lọc miền';
    chua_xoa = 'chưa xóa';
    da_xoa = 'đã xóa';
    chua_co_ten = 'chưa có tên';
    ngay_tao = 'Ngày tạo';
    them_moi_thiet_bi = 'Thêm mới thiết bị';
    chon_mien = 'chọn miền';
    dang_tai_du_lieu = 'Đang tải dữ liệu...';
    dang_xu_ly_du_lieu = 'Đang xử lý dữ liệu...';
    tim_theo_serial_number = 'Tìm theo serial number...';
    so_thu_tu = 'STT';
    logo_ung_dung = 'Logo ứng dụng';
    them_moi_thanh_cong = 'Thêm mới thành công';
    cap_nhat_thanh_cong = 'Cập nhật thành công';
    xoa_thanh_cong = 'Xóa thành công';
    loi_xu_ly_server = 'Lỗi xử lý bên Server';
    ban_da_xoa_thanh_cong = 'Bạn đã xóa thành công';
    co_loi_xay_ra = 'Có lỗi xảy ra';
    ban_chua_chon_anh_logo_ung_dung = 'Bạn chưa chọn ảnh cho logo ứng dụng';
    ban_chua_chon_anh_logo_cong_ty = 'Bạn chưa chọn ảnh cho logo công ty';
    tiep_theo = 'Tiếp theo';
    khong_the_ket_noi_may_chu_vui_long = 'Không thể kết nối máy chủ. Vui lòng thử lại';
    khong_the_ket_noi_may_chu = 'Không thể kết nối máy chủ';
    loi_du_lieu_khong_the_thay_doi = 'Lỗi dữ liệu không được thay đổi';
    du_lieu_khong_phu_hop = 'Dữ liệu không phù hợp';
    chon_tat_ca = 'Chọn tất cả';
    bo_chon_tat_ca = 'Bỏ chọn tất cả';
    tinh_trang = 'Tình trạng';
    chon_chi_so = 'Chọn chỉ số';
    ten_mien_sub = 'Tên miền (subdomain)';
    ban_chua_nhap_ten_mien = 'Bạn chưa nhập tên subdomain';
    chi_so_su_dung = 'Chỉ số sử dụng';
    gui_mat_khau_moi_thanh_cong = 'Gửi mật khẩu mới thành công';
    thong_tin_khong_thay_doi = 'Thông tin không thay đổi';
    khong_the_ket_noi_du_lieu = 'Không thể kết nối dữ liệu';
    site_khong_duoc_de_trong = 'Địa điểm không được để trống';
    to_chuc_chua_co_dia_diem = 'Tổ chức chưa có địa điểm';
    tuy_chon = 'Tùy chọn';
    tu_khoa = 'Từ khóa';
    tim_theo_email = 'Tìm kiếm theo email...';
    tim_kiem = 'Tìm kiếm';
    danh_sach_nguoi_dung = ' Danh sách người dùng';
    them_tai_khoan = 'Thêm tài khoản';
    ho_va_ten = 'Họ và tên';
    cac_email_ngan_cach_nhau = 'Các email cùng nhận được mật khẩu; ngăn cách nhau dấu phẩy!';
    vui_long_nhap_ho_ten = 'Vui lòng nhập họ tên';
    vui_long_nhap_ho_ten_5_ki_tu = 'Vui lòng nhập họ tên ít nhất 5 kí tự';
    vui_long_nhap_ho_ten_100_ki_tu = 'Vui lòng nhập họ tên nhiều nhất 100 kí tự';
    vui_long_nhap_email = 'Vui lòng nhập email';
    vui_long_nhap_email_hop_le = 'Vui lòng nhập email hợp lệ';
    vui_long_nhap_email_ki_tu = 'Vui lòng nhập email ít nhất 7 kí tự';
    cap_nhat_quyen_su_dung_cho = 'Cập nhật quyền sử dụng cho';
    cap_nhat_quyen = 'Cập nhật quyền';
    ma_quyen = 'Mã quyền';
    ma_nguoi_dung = 'Mã người dùng';
    ma_to_chuc = 'Mã tổ chức';
    mo_ta_nhom_quyen = 'Mô tả nhóm quyền';
    nhom_nguoi_dung = 'Nhóm người dùng';
    cua_hang = 'cửa hàng';
    nguoi_cap_nhat = 'Người cập nhật';
    gui_mat_khau_chac_chan = 'Gửi mật khẩu mới! Bạn có chắc chắn muốn gửi?';
    gui_mat_khau_moi = 'Gửi mật khẩu mới';
    email_khac = 'Email khác';
    cap_nhat_tai_khoan = 'Cập nhật tài khoản';
    danh_sach_ung_dung = ' Danh sách ứng dụng';
    _ung_dung = 'ứng dụng';
    ma_ung_dung = 'Mã ứng dụng';
    ten_ung_dung = 'Tên ứng dụng';
    ten_ung_dung_3_ki_tu = 'Tên ứng dụng phải có ít nhất 3 ký tự';
    nhap_ten_ung_dung = 'Vui lòng nhập tên ứng dụng';
    ban_chua_nhap_url = 'Bạn chưa nhập redirect URL';
    url_3_ki_tu = 'Redirect URL phải có ít nhất 3 ký tự';
    anh_khong_du_tieu_chuan = 'Ảnh không đủ tiêu chuẩn';
    anh_so = 'Ảnh số ';
    ban_dong_y_xoa = 'Bạn đồng ý xóa?';
    qua_trinh_xu_ly_anh_khong_thanh_cong = 'Quá trình xử lý ảnh không thành công';
    danh_sach_khach_hang = ' Danh sách khách hàng';
    ban_hay = 'Bạn hãy';
    du_lieu_duoc_ghi_nhan = 'Dữ liệu ghi nhận vào:';
    bam_vao_day = 'bấm vào đây';
    tai_lai = 'để tải lại';
    ten_khach_hang = 'Tên khách hàng';
    ma_khach_hang = 'Mã khách hàng';
    sdt = 'Số điện thoại';
    hinh_anh = 'Hình ảnh';
    them_khach_hang = 'Thêm khách hàng';
    nhap_ten_khach_hang = 'Bạn chưa nhập tên khách hàng';
    them_khach_hang_3_ki_tu = 'Tên khách hàng phải có ít nhất 3 kí tự';
    nhap_so_dien_thoai = 'Bạn chưa nhập số điện thoại';
    sdt_10_so = 'Số điện thoại phải có ít nhất 10 số';
    ngay_sinh = 'Ngày sinh';
    ban_chua_nhap_ngay_sinh = 'Bạn chưa nhập ngày sinh';
    anh_dai_dien = 'Ảnh đại diện';
    chinh_sua = 'Chỉnh sửa';
    chinh_sua_thong_tin_kh = 'Chỉnh sửa thông tin khách hàng';
    _tuy_chon = 'tùy chọn';
    anh_nhan_dang = 'Ảnh nhận dạng';
    chua_co_anh_nhan_dang = 'Chưa có ảnh nhận dạng';
    Luu_y_upload_anh = 'Lưu ý = ảnh upload lên có dung lượng ít nhất 20kb và có kích thước lớn nhất là 150x150';
    danh_sach_black_list = ' Danh sách black list';
    them_black_list = 'Thêm black list';
    ten_black_list = 'Tên black list';
    ten_black_list_3_ki_tu = 'Tên black list phải có ít nhất 3 kí tự';
    nhap_ten_black_list = 'Bạn chưa nhập tên black list';
    ma_black_list = 'Mã black list';
    chinh_sua_black_list = 'Chỉnh sửa thông tin black list';
    chua_xac_dinh = 'Chưa xác định';
    danh_sach_nhan_vien = ' Danh sách nhân viên';
    them_nhan_vien = 'Thêm nhân viên';
    ten_nhan_vien = 'Tên nhân viên';
    ma_nhan_vien = 'Mã nhân viên';
    ma_mien = 'Mã miền';
    ten_mien = 'Tên miền';
    chinh_sua_ten_nhan_vien = 'Chỉnh sửa thông tin nhân viên';
    nhap_ten_nhan_vien = 'Bạn chưa nhập tên nhân viên';
    ten_nhan_vien_3_ki_tu = 'Tên nhân viên phải có ít nhất 3 kí tự';
    to_chuc_khong_co_zalo = 'Tổ chức không có Zalo Official Account';
    danh_sach_tai_khoan_zalo = ' Danh sách tài khoản Zalo quan tâm Official Account';
    ten_hien_thi = 'Tên hiển thị';
    ten_zalo = 'Tên Zalo';
    gioi_tinh = 'Giới tính';
    chua_dat_ten = 'Chưa đặt tên';
    nam = 'Nam';
    nu = 'Nữ';
    ma_tai_khoan = 'Mã tài khoản';
    ten_tai_khoan = 'Tên tài khoản';
    nhap_ten_tai_khoan = 'Bạn chưa nhập tên tài khoản';
    ten_tai_khoan_3_ki_tu = 'Tên tài khoản có ít nhất 3 kí tự';
    ten_tai_khoan_60_ki_tu = 'Tên tài khoản có nhiều nhất 60 kí tự';
    nhap_ten_he_thong = 'Bạn chưa nhập tên trên hệ thống';
    ten_he_thong_3_ki_tu = 'Tên trên hệ thống có ít nhất 3 kí tự';
    ten_he_thong_60_ki_tu = 'Tên trên hệ thống có nhiều nhất 60 kí tự';
    nhap_ma_OA = 'Bạn chưa nhập mã tài khoản OA';
    ma_OA_3_ki_tu = 'Tài khoản OA có ít nhất 3 kí tự';
    ma_OA_60_ki_tu = 'Tài khoản OA có nhiều nhất 60 kí tự';
    nhap_invite_code = 'Bạn chưa nhập invite code';
    invite_code_3_ki_tu = 'Invite code có ít nhất 3 kí tự';
    invite_code_60_ki_tu = 'Invite code có nhiều nhất 60 kí tự';
    nhap_secret = 'Bạn chưa nhập secret';
    secret_3_ki_tu = 'Secret có ít nhất 3 kí tự';
    secret_60_ki_tu = 'Secret có nhiều nhất 60 kí tự';
    danh_sach_official = ' Danh sách Official Account';
    nguoi_duoc_chon = 'Người đã được chọn';
    chon_su_kien = 'Chọn sự kiện';
    cap_nhat_su_kien = 'Cập nhật sự kiện';
    tinh_trang_thiet_bi = 'Device status';
    gio = 'Giờ';
    ngay = 'Ngày';
    tuan = 'Tuần';
    thang = 'Tháng';
    phut = 'Phút';
    tat_ca = 'Tất cả';
    su_kien = 'Sự kiện';
    su_kien_nhan_tin_nhan = 'Sự kiện nhận tin nhắn khi nhận dạng khuôn mặt';
    danh_sach_webhook = 'Danh sách webhook';
    gui_du_lieu_mau = 'Gửi dữ liệu mẫu';
    dia_chi_nhan_duoc = 'Địa chỉ nhận được:';
    phuong_thuc_gui_di = 'Phương thức gửi đi:';
    du_lieu_gui_di = 'Dữ liệu gửi đi';
    ban_chua_nhap = 'Bạn chưa nhập';
    phai_co_it_nhat = 'phải có ít nhất 10 kí tự';
    cau_hinh_gui_email = 'Cấu hình gửi email';
    nhap_lai_mat_khau = 'Nhập lại mật khẩu';
    loai_thiet_bi = 'Loại thiết bị';
    cap_nhat_chi_so = 'Cập nhật chỉ số';
    khong_ton_tai_module = 'Không tồn tại module';
    danh_sach_cau_hinh_nhan_email_bao_cao_dinh_ky = 'Danh sách cấu hình nhận email báo cáo định kỳ';
    ten_module = 'Tên Module';
    bao_cao = 'Báo cáo';
    loai_bao_cao = 'Loại báo cáo';
    xem_theo = 'Xem theo';
    thoi_gian_bat_dau = 'Thời gian bắt đầu';
    thoi_gian_ket_thuc = 'Thời gian kết thúc';
    ngung_hoat_dong = 'Ngừng hoạt động';
    vui_long = 'Vui lòng';
    de_tai_lai = 'để tải lại';
    dang_ky_nhan_bao_cao = 'Đăng ký nhận báo cáo';
    chon_module = 'Chọn module';
    mau_bao_cao = 'Mẫu báo cáo';
    khung_gio = 'Khung giờ';
    cap_nhat_cau_hinh_nhan_mail = 'Cập nhật cấu hình nhận mail';
    tuy_chon_nhap = 'Có thể bỏ trống';
    da_co_loi_xay_ra = 'Đã có lỗi xảy ra';
    mat_khau_cu = 'Mật khẩu cũ';
    mat_khau_moi = 'Mật khẩu mới';
    doi_mat_khau = 'Đổi mật khẩu';
    bao_cao_dinh_ky = 'Báo cáo định kỳ';
    chon_to_chuc = 'Chọn tổ chức';
    xem_theo_tung_trang = 'Xem theo từng trang';
    xem_theo_tung_nguoi_dung = 'Xem theo từng người dùng';
    email_nhan_bao_cao_dinh_ky = 'Email nhận báo cáo định kỳ';
    cau_hinh = 'Cấu hình';
    quan_tri_cau_hoi = 'Quản trị câu hỏi';
    dien_thoai_may_tinh_bang = 'Điện thoại & máy tính bảng';
    nguoi_dung = 'Người dùng';
    nhap_du_lieu = 'Nhập dữ liệu';
    quan_tri_thiet_bi = 'Quản trị thiết bị';
    chon_trang = 'Chọn trang';
    tong_so_ban_ghi = 'Tổng số bản ghi';
    ten_trang = 'Tên trang';
    ten_nguoi_dung = 'Tên người dùng';
    khong_xac_dinh = 'Không xác định';
    them_cau_hinh_nhan_mail_tu_dong = 'Thêm cấu hình nhận mail tự động cho người dùng';
    vui_long_chon_dia_diem = 'Vui lòng chọn địa điểm';
    cap_nhat_cau_hinh_nhan_mail_tu_dong = 'Cập nhật cấu hình nhận mail tự động cho người dùng';
    cau_hinh_nhan_mail_canh_bao = 'Cấu hình nhận mail cảnh báo';
    them_email_nhan_mail_canh_bao = 'Thêm email nhận mail cảnh báo';
    cap_nhat_email_nhan_mail_canh_bao = 'Cập nhật email nhận mail cảnh báo';
    giam_sat_thiet_bi = 'Giám sát thiết bị';
    khong_trung_khop = 'Không trùng khớp';
    vui_long_nhap_lai = 'Vui lòng nhập lại';
    khong_duoc_de_trong = 'không được để trống';
    thong_tin_chung = 'Thông tin chung';
    ghi_chu = 'Ghi chú';
    dang_ket_noi = 'Đang kết nối';
    xac_thuc_email = 'Email xác thực';
    xac_nhan = 'Xác nhận';
    chua_xac_nhan = 'Chưa xác nhận';
    khoi_phuc = 'Khôi phục';
    cap_nhat_du_lieu_thanh_cong = 'Cập nhật dữ liệu thành công';
    ten_danh_muc = 'Tên danh mục';
    cap_nhat_thong_tin = 'Cập nhật thông tin';
    co_it_nhat = 'có ít nhất';
    ki_tu = 'kí tự';
    vui_long_nhap = 'Vui lòng nhập';
    quay_lai = 'Quay lại';
    // từ đây chưa dịch sang tiếng anh
    tieu_de_send = 'Tiêu đề gửi';
    ban_chua_nhap_tieu_de_nut_send = 'Bạn chưa nhập tiêu đều nút gửi';
    tieu_de_cancel = 'Tiêu đề nút cancel';
    ban_chua_nhap_tieu_de_nut_cancel = 'Bạn chưa nhập tiêu đề nút cancel';
    tieu_de_khung_ly_do = 'Tiêu đề khung lý do';
    ban_chua_nhap_khung_ly_do = 'Bạn chưa nhập tiêu đề khung lý do';
    tieu_de_goi_y = 'Tiêu đề gợi ý';
    ban_chua_nhap_tieu_de_goi_y = 'Bạn chưa nhập tiêu đề gợi ý';
    tieu_de_ly_do = 'Tiêu đề lý do';
    ban_chua_nhap_tieu_de_ly_do = 'Bạn chưa nhập tiêu đề lý do';
    tieu_de_thong_tin_khach_hang = 'Tiêu đề thông tin khách hàng';
    ban_chua_nhap_tieu_de_thong_tin_khach_hang = 'Bạn chưa nhập tiêu đề thông tin khách hàng';
    tieu_de_khung_ten_kh = 'Tiêu đề khung tên khách hàng';
    ban_chua_nhap_tieu_de_khung_ten_kh = 'Bạn chưa nhập tiêu đề khung tên khách hàng';
    tieu_de_khung_email_kh = 'Tiêu đề email khách hàng';
    ban_chua_nhap_tieu_de_khung_email_kh = 'Bạn chưa nhập tiêu đề email khách hàng';
    tieu_de_khung_sdt_kh = 'Tiêu đề số điện thoại khách hàng';
    ban_chua_nhap_tieu_de_khung_sdt_kh = 'Bạn chưa nhập tiêu đề số điện thoại khách hàng';
    yeu_cau_tu_chon = 'Yêu cầu tự chọn';
    yeu_cau_ten_khach_hang = 'Yêu cầu tên khách hàng';
    yeu_cau_email_khach_hang = 'Yêu cầu email khách hàng';
    yeu_cau_sdt_khach_hang = 'Yêu cầu số điện thoại khách hàng';
    thong_diep_ket_thuc = 'Thông điệp kết thúc';
    ban_chua_nhap_thong_diep_ket_thuc = 'Bạn chưa nhập thông điệp kết thúc';
    ban_chua_nhap_id = 'Bạn chưa nhập id';
    ban_chua_nhap_private_key = 'Bạn chưa nhập private key';
    ban_chua_nhap_ten_hien_thi = 'Bạn chưa nhập tên hiển thị';
    ban_chua_nhap_ma_dang_ky = 'Bạn chưa nhập mã đăng ký';
    luu_cau_hinh = 'Lưu cấu hình';
    ma_dang_ky = 'Mã đăng ký';
    dang_ky_su_kien = 'Đăng ký sự kiện';
    ban_chua_cau_hinh_oa_zalo = 'Bạn chưa cấu hình oa zalo';
    danh_sach_su_kien = 'Danh sách sự kiện';
    nguoi_chua_chon = 'chưa chọn';
    //
    huy_dang_ky_thanh_cong = 'Hủy đăng ký thành công';
    Anh_chi_co_muon_nhan_bao_cao_nay_qua_email = 'Anh chị có muốn nhận báo cáo này định kỳ qua email không?';
    Bao_cao_nay_se_duoc_gui_tu_dong = 'Báo cáo này sẽ được tự động gửi tới email:';
    vao = 'vào';
    cua_ky_bao_cao = 'của kỳ báo cáo';
    oh_ngay_tiep_theo = '0 giờ ngày tiếp theo';
    oh_thu_hai_dau_tuan = '0 giờ thứ hai đầu tuần';
    oh_ngay_dau_tien_trong_thang = '0 giờ ngày đầu tiên trong tháng';
    oh_ngay_dau_tien_trong_nam = '0 giờ ngày đầu tiên trong năm';
    Dang_ky_thanh_cong = 'Đăng ký thành công';
    To_chuc = 'Tổ chức';
    Tao_lich_gui_bao_cao = 'Tạo lịch gửi báo cáo định kỳ';
    Cap_nhat_lich_gui_bao_cao = ' Cập nhật lịch gửi báo cáo định kỳ';
    Danh_sach_dang_ky_nhan_mail_dinh_ky = 'Danh sách đăng kí nhận mail định kỳ';
    Thoi_gian_dang_ky_nhan_email = 'Thời gian lấy dữ liệu:';
    Ban_chua_dang_ky_nhan_bao_cao = 'Bạn chưa đăng kí nhận báo cáo';
    Hien_nut_bo_qua = 'Hiển thị button bỏ qua';
    dinh_dang_gio_hien_thi = 'Định dạng giờ hiển thị';
    h_12 = '12 giờ';
    h_24 = '24 giờ';
    ngan_hop_hop_thoai_xuat_hien = 'Ngăn không cho hộp thoại này xuất hiện!';
    luu_phien_lam_viec_hien_tai = 'Lưu phiên làm việc';

}
