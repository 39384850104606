import { AdminLanguage } from './index';
export class AdminEngLanguage extends AdminLanguage {
    // Sites
    STT = 'No.';
    danh_sach_dia_diem = 'Site list';
    dia_diem = 'Site';
    them_dia_diem = 'Add site';
    cap_nhat_dia_diem = 'update site';
    to_chuc_ = 'Organization:';
    ma = 'Name';
    ten_viet_tat = 'Short name';
    ma_dia_diem = 'Site code';
    trang_thai = 'Status';
    xu_ly = 'Process';
    dong = 'Close';
    ban_co_chac_chan_muon_xoa = 'Are you sure to delete?';
    xac_nhan_xoa = 'Do you really want to delete the record? This process will not be stopped';
    them_dia_diem_mac_dinh = 'Add default site';
    to_chuc = 'Organization';
    ten_dia_diem = 'Site name';
    ban_chua_nhap_ten_dia_diem = 'You did not import site';
    dia_diem_co_it_nhat_3_ki_tu = 'Site has at lest 3 characters';
    dia_diem_co_nhieu_nhat_50_ki_tu = 'Site input just up to 50 characters';
    ban_chua_nhap_ma_dia_diem = 'You did not import site';
    ma_dia_diem_co_it_nhat_3_ki_tu = 'Site code has at lest 3 characters';
    ma_dia_diem_co_nhieu_nhat_50_ki_tu = 'Site code input just up to 50 characters';
    tom_tat = 'Description';
    ban_chua_nhap_tom_tat = 'You did not import short description';
    tom_tat_co_it_nhat_3_ki_tu = 'Short description has at lest 3 characters';
    tom_tat_co_nhieu_nhat_50_ki_tu = 'Short description input just up to 50 characters';
    danh_muc = 'Catagory';
    thoi_gian_hoat_dong = 'Operation time';
    gio_mo_cua = 'Open time';
    hoat_dong = 'Operation';
    co = 'Yes';
    khong = 'No';
    xoa = 'Delete';
    them = 'Add';
    cap_nhat = 'Update';
    thuoc_dia_diem = 'Belong to site ';
    ban_chua_nhap_gio_mo_cua = 'Pls import open time';
    ban_chua_nhap_gio_dong_cua = 'Pls import close time';
    yeu_cau_nhap_day_du_thong_tin = '(*) Pls import full information';
    //  Company
    danh_sach_cong_ty = ' Company list';
    them_cong_ty = 'Add company';
    cong_ty = 'Company';
    mo_ta = 'Description';
    ngay_tham_gia = 'Time to activate';
    ban_chua_nhap_ten_cong_ty = 'Pl import your company name';
    logo_cong_ty = 'Company logo';
    ma_cong_ty = 'Company code';
    ban_chua_nhap_ma_cong_ty = 'Pls import your compnay code';
    ban_chua_nhap_ten_viet_tat = 'Pls import your short name';
    ban_chua_nhap_mo_ta = 'Pls import description';
    ban_chua_nhap_subdomain = 'Pls import subdomain';
    Subdomain = 'Subdomain';
    nguoi_dung_quan_tri = 'Administrator';
    ban_chua_nhap_nguoi_quan_tri = 'Pls import administrator';
    Email_quan_tri = 'Admin Email';
    ban_chua_nhap_email = 'Pls import email';
    mat_khau = 'Password';
    ban_chua_nhap_mat_khau = 'Pls import your password';
    Module_su_dung = 'Module activated';
    cap_nhat_cong_ty = 'Update your company';
    // Location
    danh_sach_vi_tri = ' Location list';
    tim_kiem_ = 'Search:';
    vi_tri = 'Location';
    ma_vi_tri = 'Location code';
    them_vi_tri = 'Add location';
    khong_co_du_lieu = 'No data';
    cap_nhat_vi_tri = 'Update location';
    chua_chon = 'Not yet selected';
    ban_chua_nhap_vi_tri = 'Pls import location';
    vi_tri_co_it_nhat_3_ki_tu = 'Location with at least 3 characters';
    vi_tri_co_nhieu_nhat_50_ki_tu = 'Location up to 50 characters';
    // Category
    danh_sach_danh_muc = ' Catagory list';
    them_moi = 'Add new';
    ngay_cap_nhat = 'Day update';
    them_danh_muc = 'Add catagory';
    cap_nhat_danh_muc = 'update catagory';
    ma_danh_muc = 'Catagory code';
    ban_chua_nhap_danh_muc = 'Pls import catagory';
    danh_muc_co_it_nhat_3_ki_tu = 'Catagory has at least 3 characters';
    // Device list
    danh_sach_thiet_bi = ' Device list';
    so_dong = 'Show = ';
    chua_cap_nhat = 'Not yet update';
    them_thiet_bi = 'Ddd device';
    cap_nhat_thiet_bi = 'Update device';
    ban_chua_nhap_seri = 'Pls import serial number';
    serial_number_co_it_nhat_3_ki_tu = 'Serial number has at least 3 characters';
    ban_chua_nhap_IP = 'Pls import IP';
    ban_chua_chon_vi_tri = 'pls select location';
    ten_thiet_bi = 'Device name';
    che_do = 'Mode';
    dia_chi_IP = 'IP address';
    dia_chi_wan_ip = 'WAN IP address';
    dang_hoat_dong = 'Operating';
    ngat_ket_noi = 'Disconnecting';
    ban_co_chac_chan_muon_khoi_phuc = 'Are you sure to restore data?';
    xin_luu_y = 'Note that!! this could not stop any more';
    ban_chua_nhap_ten_thiet_bi = 'Pls import device name';
    kich_hoat = 'Activated';
    dia_chi_mac = 'MAC Address';
    cong_http = 'Http port';
    cong_https = 'Https port';
    chon_loc_mien = 'Select group';
    chua_xoa = 'Actived';
    da_xoa = 'Deleted';
    chua_co_ten = 'Pls import your title';
    ngay_tao = 'Activated date';
    them_moi_thiet_bi = 'Add new device';
    chon_mien = 'select group';
    dang_tai_du_lieu = 'Data downloading...';
    dang_xu_ly_du_lieu = 'Data processing...';
    tim_theo_serial_number = 'Search on serial number...';
    so_thu_tu = 'No.';
    logo_ung_dung = 'Application logo';
    them_moi_thanh_cong = 'Add successfully';
    cap_nhat_thanh_cong = 'Update successful';
    xoa_thanh_cong = 'Delete successful';
    loi_xu_ly_server = 'Error processing server side';
    ban_da_xoa_thanh_cong = 'You have successfully deleted';
    co_loi_xay_ra = 'Something went wrong';
    ban_chua_chon_anh_logo_ung_dung = 'You have not selected an image for the application logo';
    ban_chua_chon_anh_logo_cong_ty = 'You have not selected a photo for your company logo';
    tiep_theo = 'Next';
    khong_the_ket_noi_may_chu_vui_long = 'Unable to connect to the server. Please try again';
    khong_the_ket_noi_may_chu = 'Unable to connect to the server';
    loi_du_lieu_khong_the_thay_doi = 'Data error not changed';
    du_lieu_khong_phu_hop = 'Data inconsistent';
    chon_tat_ca = 'Select all';
    bo_chon_tat_ca = 'Deselect all';
    tinh_trang = 'Status';
    chon_chi_so = 'Choose an indicator';
    ten_mien_sub = 'Subdomain';
    ban_chua_nhap_ten_mien = 'You did not enter a subdomain name';
    chi_so_su_dung = 'Usage index';
    gui_mat_khau_moi_thanh_cong = 'New password sent successfully';
    thong_tin_khong_thay_doi = 'Information unchanged ';
    khong_the_ket_noi_du_lieu = 'Unable to connect to the data';
    site_khong_duoc_de_trong = 'Place cannot be empty';
    to_chuc_chua_co_dia_diem = 'Organization has no site yet';
    tuy_chon = 'Option';
    tu_khoa = 'Key word';
    tim_theo_email = 'Search by email ... ';
    tim_kiem = 'Search';
    danh_sach_nguoi_dung = ' User list';
    them_tai_khoan = 'Add account';
    ho_va_ten = 'Full name';
    cac_email_ngan_cach_nhau = 'Emails received the same password; separated by commas!';
    vui_long_nhap_ho_ten = 'Please enter your full name';
    vui_long_nhap_ho_ten_5_ki_tu = 'Please enter your full name at least 5 characters';
    vui_long_nhap_ho_ten_100_ki_tu = 'Please enter your full name at most 100 characters';
    vui_long_nhap_email = 'Please enter email';
    vui_long_nhap_email_hop_le = 'Please enter valid email';
    vui_long_nhap_email_ki_tu = 'Please enter email at least 7 characters';
    cap_nhat_quyen_su_dung_cho = 'Update user permissions for';
    cap_nhat_quyen = 'Update permissions';
    ma_quyen = 'Permissions code';
    ma_nguoi_dung = 'User ID';
    ma_to_chuc = 'Organization code';
    mo_ta_nhom_quyen = 'Permission group';
    nhom_nguoi_dung = 'User group';
    cua_hang = 'store';
    nguoi_cap_nhat = 'Updated Person';
    gui_mat_khau_chac_chan = 'Send a new password! Are you sure you want to submit?';
    gui_mat_khau_moi = 'Send a new password';
    email_khac = 'Other email';
    cap_nhat_tai_khoan = 'Update account';
    danh_sach_ung_dung = ' List of applications';
    _ung_dung = 'application';
    ma_ung_dung = 'App code';
    ten_ung_dung = 'App name';
    ten_ung_dung_3_ki_tu = 'App name must be at least 3 characters';
    nhap_ten_ung_dung = 'Please enter the app name';
    ban_chua_nhap_url = 'You have not entered redirect URL';
    url_3_ki_tu = 'Redirect URL must be at least 3 characters';
    anh_khong_du_tieu_chuan = 'Unqualified photo';
    anh_so = 'Photo ';
    ban_dong_y_xoa = 'Do you agree to delete?';
    qua_trinh_xu_ly_anh_khong_thanh_cong = 'Image processing failed';
    danh_sach_khach_hang = ' List of customers';
    ban_hay = 'You';
    du_lieu_duoc_ghi_nhan = 'Data was recorded at = ';
    bam_vao_day = 'click here';
    tai_lai = 'try again';
    ten_khach_hang = 'Customer name';
    ma_khach_hang = 'Customer code';
    sdt = 'Telephone number';
    hinh_anh = 'Image';
    them_khach_hang = 'Add customer ';
    nhap_ten_khach_hang = 'You did not enter a customer name';
    them_khach_hang_3_ki_tu = 'Customer name must have at least 3 characters';
    nhap_so_dien_thoai = 'You did not enter a phone number';
    sdt_10_so = 'Phone numbers must have at least 10 numbers';
    ngay_sinh = 'Date of birth';
    ban_chua_nhap_ngay_sinh = 'You have not entered a birthday';
    anh_dai_dien = 'Avatar';
    chinh_sua = 'Edit';
    chinh_sua_thong_tin_kh = 'Edit customer information ';
    _tuy_chon = 'option';
    anh_nhan_dang = 'Identification photo';
    chua_co_anh_nhan_dang = 'No identity photos yet';
    Luu_y_upload_anh = 'Note: Uploaded photos must be at least 20kb and the largest size is 150x150 ';
    danh_sach_black_list = ' Black list';
    them_black_list = 'Add black list';
    ten_black_list = 'Black list name';
    ten_black_list_3_ki_tu = 'The black list name must have at least 3 characters';
    nhap_ten_black_list = 'You have not entered the name black list ';
    ma_black_list = 'Black list code ';
    chinh_sua_black_list = 'Edit black list information';
    chua_xac_dinh = 'Undefined';
    danh_sach_nhan_vien = ' List of employee';
    them_nhan_vien = 'Add staff';
    ten_nhan_vien = 'Staff  name';
    ma_nhan_vien = 'Employee code';
    ma_mien = 'Domain code';
    ten_mien = 'Domain';
    chinh_sua_ten_nhan_vien = 'Edit employee information';
    nhap_ten_nhan_vien = 'You did not enter an employee name';
    ten_nhan_vien_3_ki_tu = 'Employee name must be at least 3 characters';
    to_chuc_khong_co_zalo = 'Organization without Zalo Official Account ';
    danh_sach_tai_khoan_zalo = ' List of Zalo accounts of interest Official Account';
    ten_hien_thi = 'Display name';
    ten_zalo = 'Name Zalo ';
    gioi_tinh = 'Gender';
    chua_dat_ten = 'Unnamed';
    nam = 'Male';
    nu = 'Female';
    ma_tai_khoan = 'Account code';
    ten_tai_khoan = 'Account name';
    nhap_ten_tai_khoan = 'You did not enter an account name';
    ten_tai_khoan_3_ki_tu = 'The account name has at least 3 characters';
    ten_tai_khoan_60_ki_tu = 'Account name has at most 60 characters';
    nhap_ten_he_thong = 'You did not enter a name on the system ';
    ten_he_thong_3_ki_tu = 'The name on the system has at least 3 characters';
    ten_he_thong_60_ki_tu = 'Names on the system have at most 60 characters';
    nhap_ma_OA = 'You have not entered your OA account code ';
    ma_OA_3_ki_tu = 'OA account has at least 3 characters';
    ma_OA_60_ki_tu = 'OA accounts have at most 60 characters';
    nhap_invite_code = 'You have not entered the invite code ';
    invite_code_3_ki_tu = 'Invite code has at least 3 characters';
    invite_code_60_ki_tu = 'Invite code has at most 60 characters';
    nhap_secret = 'You have not entered the secret ';
    secret_3_ki_tu = 'Secret has at least 3 characters';
    secret_60_ki_tu = 'Secret has at most 60 characters';
    danh_sach_official = ' Official Account List ';
    nguoi_duoc_chon = 'The chosen one';
    chon_su_kien = 'Select event ';
    cap_nhat_su_kien = 'Update event';
    tinh_trang_thiet_bi = 'Status';
    gio = 'Hour';
    ngay = 'Day';
    tuan = 'Week';
    thang = 'Month';
    phut = 'Munite';
    tat_ca = 'All';
    su_kien = 'Event';
    su_kien_nhan_tin_nhan = 'Event that receives a message when a face is detected';
    danh_sach_webhook = ' List webhook';
    gui_du_lieu_mau = 'Sample data';
    dia_chi_nhan_duoc = 'Address received:';
    phuong_thuc_gui_di = 'Method of sending:';
    du_lieu_gui_di = 'Data sent';
    ban_chua_nhap = 'You have not entered';
    phai_co_it_nhat = 'must be at least 10 characters long';
    cau_hinh_gui_email = 'Email Configuation';
    nhap_lai_mat_khau = 'Confirm Password';
    loai_thiet_bi = 'Brand';
    cap_nhat_chi_so = 'Update index';
    khong_ton_tai_module = 'Module does not exist';
    danh_sach_cau_hinh_nhan_email_bao_cao_dinh_ky = 'Table of email schedule config';
    ten_module = 'Module name';
    bao_cao = 'Report name';
    loai_bao_cao = 'Type of report';
    xem_theo = 'View by';
    thoi_gian_bat_dau = 'Start time';
    thoi_gian_ket_thuc = 'End time';
    ngung_hoat_dong = 'Inactive';
    vui_long = 'Please';
    de_tai_lai = 'reload';
    dang_ky_nhan_bao_cao = 'Add Config Schedule Report';
    chon_module = 'Pick module';
    mau_bao_cao = 'Report';
    khung_gio = 'Time slot';
    cap_nhat_cau_hinh_nhan_mail = 'Update Config Schedule Report';
    tuy_chon_nhap = 'May be empty';
    da_co_loi_xay_ra = 'Something went wrong';
    mat_khau_cu = 'Old password';
    mat_khau_moi = 'New password';
    doi_mat_khau = 'Change password';
    bao_cao_dinh_ky = 'Report schedule';
    chon_to_chuc = 'Select organization';
    xem_theo_tung_trang = 'View on page';
    xem_theo_tung_nguoi_dung = 'view on user';
    email_nhan_bao_cao_dinh_ky = 'Email recurring reports';
    cau_hinh = 'Config';
    quan_tri_cau_hoi = 'Question manager';
    dien_thoai_may_tinh_bang = 'Tablet';
    nguoi_dung = 'User';
    nhap_du_lieu = 'Insert information';
    quan_tri_thiet_bi = 'Terminal Manager';
    chon_trang = 'Select page';
    tong_so_ban_ghi = 'Total record';
    ten_trang = 'Page name';
    ten_nguoi_dung = 'Username';
    khong_xac_dinh = 'Unknown';
    them_cau_hinh_nhan_mail_tu_dong = 'Add a configuration to automatically receive mail for users';
    vui_long_chon_dia_diem = 'Please select a site';
    cap_nhat_cau_hinh_nhan_mail_tu_dong = 'Update configuration of automatic mail delivery for users';
    cau_hinh_nhan_mail_canh_bao = 'Configuration to receive mail warning';
    them_email_nhan_mail_canh_bao = 'Add a configuration to receive mail warning';
    cap_nhat_email_nhan_mail_canh_bao = 'Update a configuration to receive mail warning';
    giam_sat_thiet_bi = 'Device monitoring';
    khong_trung_khop = 'does not match';
    vui_long_nhap_lai = 'Please type again';
    khong_duoc_de_trong = 'not be empty';
    thong_tin_chung = 'General information';
    ghi_chu = 'Description';
    dang_ket_noi = 'Online';
    xac_thuc_email = 'Email verified';
    xac_nhan = 'Accept';
    chua_xac_nhan = 'Not accept';
    khoi_phuc = 'Restore';
    cap_nhat_du_lieu_thanh_cong = 'Update info success';
    ten_danh_muc = 'Category name';
    cap_nhat_thong_tin = 'Update infomation';
    co_it_nhat = 'at least';
    ki_tu = 'character';
    vui_long_nhap = 'Please type';
    quay_lai = 'Go back';
    tieu_de_send = 'Button send title';
    ban_chua_nhap_tieu_de_nut_send = 'Please type button send title';
    tieu_de_cancel = 'Button send cancel';
    ban_chua_nhap_tieu_de_nut_cancel = 'Please type button cancel title';
    tieu_de_khung_ly_do = 'Reason title area';
    ban_chua_nhap_khung_ly_do = 'Please type reason title area';
    tieu_de_goi_y = 'Suggestions';
    ban_chua_nhap_tieu_de_goi_y = 'Please type suggest';
    tieu_de_ly_do = 'Reason title';
    ban_chua_nhap_tieu_de_ly_do = 'Please type reason title';
    tieu_de_thong_tin_khach_hang = 'Customer information title';
    ban_chua_nhap_tieu_de_thong_tin_khach_hang = 'Please type customer information title';
    tieu_de_khung_ten_kh = 'Customer name';
    ban_chua_nhap_tieu_de_khung_ten_kh = 'Please type customer name';
    tieu_de_khung_email_kh = 'Customer email';
    ban_chua_nhap_tieu_de_khung_email_kh = 'Please type customer email';
    tieu_de_khung_sdt_kh = 'Customer phone';
    ban_chua_nhap_tieu_de_khung_sdt_kh = 'Please type customer email';
    yeu_cau_tu_chon = 'Requirement';
    yeu_cau_ten_khach_hang = 'Customer name requirement';
    yeu_cau_email_khach_hang = 'Customer email requirement';
    yeu_cau_sdt_khach_hang = 'Customer phone requirement';
    thong_diep_ket_thuc = 'Finish message';
    ban_chua_nhap_thong_diep_ket_thuc = 'Please type finish message';
    ban_chua_nhap_id = 'Please type id';
    ban_chua_nhap_private_key = 'Please type private key';
    ban_chua_nhap_ten_hien_thi = 'Please type display name';
    ban_chua_nhap_ma_dang_ky = 'Please type secret';
    luu_cau_hinh = 'Save config';
    ma_dang_ky = 'Secret';
    dang_ky_su_kien = 'Event registration';
    ban_chua_cau_hinh_oa_zalo = 'You have not configured oa zalo';
    danh_sach_su_kien = 'List of event';
    nguoi_chua_chon = 'Unselected';
    huy_dang_ky_thanh_cong = 'Unsubcribe success';
    Anh_chi_co_muon_nhan_bao_cao_nay_qua_email = 'Do you want to receive this report periodically via email?';
    Bao_cao_nay_se_duoc_gui_tu_dong = 'This report will be automatically sent to the email:';
    vao = 'at';
    cua_ky_bao_cao = 'of the reporting period';
    oh_ngay_tiep_theo = '0 o\'clock the next day';
    oh_thu_hai_dau_tuan = '0 o\'clock on the first Monday of the week';
    oh_ngay_dau_tien_trong_thang = '0 o\'clock the first day of the month';
    oh_ngay_dau_tien_trong_nam = '0 o\'clock the first day of the year';
    Dang_ky_thanh_cong = 'Registration successful';
    To_chuc = 'Organization';
    Tao_lich_gui_bao_cao = 'Create a schedule to send periodic reports';
    Cap_nhat_lich_gui_bao_cao = ' Update the schedule to send reports periodically';
    Danh_sach_dang_ky_nhan_mail_dinh_ky = ' Regular mailing list';
    Thoi_gian_dang_ky_nhan_email = 'Time to retrieve data:';
    Ban_chua_dang_ky_nhan_bao_cao = 'You have not subscribed to the report';
    Hien_nut_bo_qua = 'Hien_nut_bo_qua';
    dinh_dang_gio_hien_thi = 'Display time format';
    h_12 = '12 hours';
    h_24 =  '24 hours';
    ngan_hop_hop_thoai_xuat_hien = 'Prevent this dialog box from appearing!';
    luu_phien_lam_viec_hien_tai = 'Save working session';

}
